class AbstractAPIConnector {
    APIUrl = ''

    // eslint-disable-next-line class-methods-use-this
    async _fetchJSONFromURL (url, requestOptions) {
        return fetch(url, requestOptions)
            .then(response => response.json())
    }
}

export default AbstractAPIConnector