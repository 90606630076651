export default {
    en: {
        common: {
            buttons: {
                save: 'Save',
                apply: 'Apply',
                delete: 'Delete',
                reset: 'Reset',
                add: 'Add',
                new: 'New entry',
                no: 'No',
                close: 'Close',
                showMore: 'Show more',
                showLess: 'Show less',
                enterFullscreen: 'Enter fullscreen',
                exitFullscreen: 'Exit fullscreen',
                yes: 'Yes',
                previousPage: 'Previous page',
                nextPage: 'Next page',
                showInGoogleMaps: 'Show in Google Maps',
                continue: 'Continue',
                back: 'Back'
            },
            terms: {
                logic: {
                    or: 'or',
                    lt: 'less than',
                    gt: 'greater than',
                    and: 'and',
                    bt: 'between'
                },
                chosen: 'Chosen',
                pointInTime: 'Time',
                timestamp: 'Timestamp',
                group: 'Group',
                sum: 'Sum',
                total: 'Total',
                average: 'Average',
                specific: 'Specific',
                absolute: 'Absolute',
                noSum: 'Sum invalid',
                selected: 'selected',
                copy: 'Copy',
                loading: 'Loading',
                area: 'area',
                typ: 'type',
                unit: 'Unit',
                value: 'Value',
                attribute: 'Attribute',
                formula: 'Formula',
                preview: 'Preview',
                costs: {
                    tco: 'Total costs of ownership',
                    invest: 'Invest costs',
                    energy: 'Energy costs',
                    operate: 'Operating costs'
                }
            },
            objects: {
                site: 'Site'
            },
            time: {
                years: 'Years',
                hourly: 'hourly',
                daily: 'daily',
                weekly: 'weekly',
                monthly: 'monthly',
                quarterly: 'quarterly',
                yearly: 'yearly',
                thrityDays: '30-days',
                ninetyDays: '90-days',
                seconds: 'second | seconds',
                minutes: 'minute | minutes',
                hours: 'hour | hours',
                months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                quarters: ['1st quarter', '2nd quarter', '3rd quarter', '4th quarter']
            },
            notifications: {
                apiError: 'A connection error occured.\n Please try again. \nShould the problem persist, please contact Support.',
                stepValidationError: 'An invalid data combination was entered for this step.\n Please check the data again for completeness and possible mistakes.',
                internalServerError500: 'The request could not be processed. \nThere is a technical error with the server.',
                unknownError: 'A technical Error occured.\n Please try again.',
                supportNecessary: '\nPlease contact support, be sure to include the error details below.',
                supportOptional: '\nIf the problem persists, contact support. Please include the error details below.',
                errorTitle: 'Error',
                errorDetails: 'Error details',
                notNegative: 'Cannot be negative',
                maxValue: 'Must not be more than %s%',
                minValue: 'Must be at least %s%',
                notEmpty: 'Must not be empty',
                percentage: 'Must be a percentage value between 0 and 100',
                relativeNumberOrAsterisk: 'Must be a number between 0 and 1 or an \'*\'',
                numberOrAsterisk: 'Must be a number or an \'*\'',
                unique: 'Must be unique',
                calcTime: 'Calculation time',
                selectExportFormat: 'Please select your preferred export-format.',
            }
        },
        rudiError: {
            badRequestError: {
                '400A1': 'The system cannot process the calculation.\nAt least one load profile is required for the calculation.\nPlease ensure that load profiles have been calculated or uploaded.',
                '400A2': 'The system cannot process the calculation.\nAt least one load profile could not be read.\nPlease make sure that all data is formatted correctly.',
                '400A3': 'The system cannot process the calculation.\nAt least one needed Energy cannot be provided because there are no suppliers that could convert the demand energy into the needed energy.\nPlease check the selected suppliers.',
                '400B': 'The system cannot process the calculation.\nThere is a technical error.',
                '400C1': 'The system cannot process the calculation.\nNo solution can be found with the values entered.\nPlease restrict your selection (example: end energies, suppliers).',
                '400C2': 'The system cannot process the calculation.\nNo solution can be found with the values entered.\nPlease expand your selection (example: end energies, suppliers).',
                '400D': 'The system cannot process the calculation.\nNo solution can be found with the entered formula.\nPlease check the formula input and enter a valid formula.'
            }
        }
    },
    de: {
        common: {
            buttons: {
                save: 'Speichern',
                apply: 'Übernehmen',
                delete: 'Löschen',
                reset: 'Zurücksetzen',
                add: 'Hinzufügen',
                new: 'Neuer Eintrag',
                no: 'Nein',
                close: 'Schließen',
                showMore: 'Mehr anzeigen',
                showLess: 'Weniger anzeigen',
                enterFullscreen: 'In Vollbild Modus wechseln',
                exitFullscreen: 'Vollbild Modus verlassen',
                yes: 'Ja',
                previousPage: 'Vorherige Seite',
                nextPage: 'Nächste Seite',
                showInGoogleMaps: 'In Google Maps anzeigen',
                continue: 'Weiter',
                back: 'Zurück'
            },
            terms: {
                logic: {
                    or: 'oder',
                    lt: 'weniger als',
                    gt: 'mehr als',
                    and: 'und',
                    bt: 'zwischen'
                },
                chosen: 'Ausgewählt',
                pointInTime: 'Zeitpunkt',
                timestamp: 'Zeitstempel',
                group: 'Gruppe',
                sum: 'Summe',
                total: 'Gesamt',
                average: 'Durchschnitt',
                specific: 'Spezifisch',
                absolute: 'Absolut',
                noSum: 'Summe ungültig',
                selected: 'ausgewählt',
                copy: 'Kopie',
                loading: 'Lade',
                area: 'Fläche',
                typ: 'Typ',
                unit: 'Einheit',
                value: 'Wert',
                attribute: 'Attribut',
                formula: 'Formel',
                preview: 'Vorschau',
                costs: {
                    tco: 'Gesamtkosten im Betrachtungszeitraum',
                    invest: 'Investitionskosten',
                    energy: 'Energiekosten',
                    operate: 'Betriebskosten'
                }
            },
            objects: {
                site: 'Liegenschaft'
            },
            time: {
                years: 'Jahre',
                hourly: 'stündlich',
                daily: 'täglich',
                weekly: 'wöchentlich',
                monthly: 'monatlich',
                quarterly: 'quartal',
                yearly: 'jährlich',
                thrityDays: '30 tägig',
                ninetyDays: '90 tägig',
                seconds: 'Sekunde | Sekunden',
                minutes: 'Minute | Minuten',
                hours: 'Stunde | Stunden',
                months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
                quarters: ['1. Quartal', '2. Quartal', '3. Quartal', '4. Quartal']
            },
            notifications: {
                apiError: 'Ein Verbindungsproblem ist aufgetreten.\n  Bitte versuchen Sie es erneut. \nSollte das Problem immernoch bestehen, wenden Sie sich an den Support.',
                stepValidationError: 'Für diesen Schritt wurde eine ungültige Datenkombination eingegeben. Bitte überprüfen Sie die Eingabe auf Vollständigkeit und eventuelle Eingabefehler.',
                internalServerError500: 'Die Anfrage konnte nicht bearbeitet werden. \nEs liegt ein technischer Fehler beim Server vor.',
                unknownError: 'Es ist ein technischer Fehler aufgetreten. \nBitte versuchen Sie es erneut.',
                supportNecessary: '\nBitte wenden Sie sich an den Support, geben Sie dabei bitte unbedingt die untenstehenden Fehlerdetails mit an.',
                supportOptional: '\nSollte das Problem weiterhin bestehen, wenden Sie sich an den Support. Geben Sie dabei die untenstehenden Fehlerdetails mit an.',
                errorTitle: 'Fehler',
                errorDetails: 'Fehlerdetails',
                notNegative: 'Darf nicht negativ sein',
                maxValue: 'Darf maximal %s% sein',
                minValue: 'Muss mindestens %s% sein',
                notEmpty: 'Darf nicht leer sein',
                percentage: 'Muss ein Prozentwert zwischen 0 und 100 sein',
                relativeNumberOrAsterisk: 'Muss eine Nummer zwischen 0 und 1 oder ein \'*\' sein',
                numberOrAsterisk: 'Muss eine Nummer oder ein \'*\' sein',
                unique: 'Muss einzigartig sein',
                calcTime: 'Berechnungszeit',
                selectExportFormat: 'Bitte wählen Sie ihr bevorzugtes Exportformat.',
            },
        },
        rudiError: {
            badRequestError: {
                '400A1': 'Das System kann die Berechnung nicht durchführen.\nFür die Berechnung wird mindestens ein Lastgang benötigt.\nBitte stellen Sie sicher, dass Lastgänge berechnet oder hochgeladen wurden.',
                '400A2': 'Das System kann die Berechnung nicht durchführen.\nMindestens ein Lastgang konnte nicht eingelesen werden.\nBitte stellen Sie sicher, dass alle Daten korrekt formattiert sind.',
                '400A3': 'Das System kann die Berechnung nicht durchführen.\nMindestens eine Nutzenergie kann nicht bereitgestellt werden, da es keine Erzeuger gibt, welche die Endenergien umwandeln könnten.\nBitte überprüfen Sie die ausgewählten Erzeuger.',
                '400B': 'Das System kann die Berechnung nicht durchführen.\nEs liegt ein technischer Fehler vor.',
                '400C1': 'Das System kann die Berechnung nicht durchführen.\nMit den eingegebenen Werten kann keine Lösung gefunden werden.\nBitte schränken Sie Ihre Auswahl ein (Beispiel: Endenergien, Erzeuger).',
                '400C2': 'Das System kann die Berechnung nicht durchführen.\nMit den eingegebenen Werten kann keine Lösung gefunden werden.\nBitte erweitern Sie Ihre Auswahl (Beispiel: Endenergien, Erzeuger).',
                '400D': 'Das System kann die Berechnung nicht durchführen.\nMit der eingegebenen Formel kann keine Lösung gefunden werden.\nBitte überprüfen Sie die Formeleingabe und geben Sie eine valide Formel ein.'
            }
        }
    }
}