export default {
    allSuppliers (state) {
        if (!state.scenarioOptions.supplier) {
            return []
        }
        let allSuppliers = []

        allSuppliers = state.scenarioOptions.supplier
        state.scenarios.forEach(scenario => {
            scenario.suppliers.forEach(s => !allSuppliers.some(option => option.id === s.id) && allSuppliers.push(s))
        })

        return allSuppliers
    },
    allStorages (state) {
        if (!state.scenarioOptions.storages) {
            return []
        }
        let allStorages = []

        allStorages = state.scenarioOptions.storages
        state.scenarios.forEach(scenario => {
            scenario.storages.forEach(s => !allStorages.some(option => option.id === s.id) && allStorages.push(s))
        })

        return allStorages
    },
    allSystems (state, getters) {
        return [...getters.allSuppliers, ...getters.allStorages]
    },
    allEndEnergies (state) {
        if (!state.scenarioOptions.endEnergies) {
            return []
        }
        const allEnergies = state.scenarioOptions.endEnergies;

        state.scenarios.forEach(scenario => {
            scenario.endEnergies.forEach(e => !allEnergies.some(option => option.id === e.id) && allEnergies.push(e))
        })

        return allEnergies
    },
    allNeededEnergies (state) {
        return state.energyTypes;
    },
    /**
     * Returns a combined array of all energyTypes (from BERTA), end energy options (from RUDI) and end energies in the scenarios that were created
     * additionally to the options (e.g. created by the user on view3). Note that references of the energies will be returned, not copies.
     * @param {Object} state The stores state
     * @param {Object} getters The stores getters
     * @returns Array of all energies from the different sources.
     */
    allEnergies (state, getters) {
        return [...getters.allEndEnergies, ...getters.allNeededEnergies]
    },
    allBuildings (state) {
        return state.buildingGroups.map(group => group.data).flat()
    },
    estimatedScenarioLoadingTime (state) {
        const relevantScenarios = state.scenarios.filter((scenario) => !scenario.result)
        if (!relevantScenarios.length) {
            return 0
        }
        let numberOfStorages = 0
        relevantScenarios.forEach(scenario => {
            if (scenario.usedSuppliers) {
                numberOfStorages += Object.values(scenario.usedStorages).filter(Boolean).length
            }
        })

        return (105 + 15 * (numberOfStorages / relevantScenarios.length)) * (1 + 0.2 * relevantScenarios.length)
    },
    getNeededEnergyById: (state) => (id) => {
        const idToCheck = parseInt(id)
        return state.energyTypes.find(energy => energy.id === idToCheck)
    },
    getBuildingById: (state) => (id) => {
        const buildingFindFn = (building) => building.id === id;
        const possibleGroup = state.buildingGroups.find((group) => group.data.find(buildingFindFn));
        let possibleBuilding = null;
        if (possibleGroup) {
            possibleBuilding = possibleGroup.data.find(buildingFindFn) || null;
        }
        return possibleBuilding;
    },
    getBuildingGroupIndex: (state) => (buildingGroup) => {
        return state.buildingGroups.findIndex((group) => group.id === buildingGroup.id);
    },
}