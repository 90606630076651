class Entity {
    id;
    name;
    color;

    constructor (id, name, color) {
        this.id = id;
        this.name = name;
        this.color = color;
    }

    /**
     * Checks if an entity is usable in a given context, defined by a selection of other entities.
     * General information: Even though we might not use all the other entity types in the function header, the call to the entities (supplier and energy)
     * isUsable function should always look the same. In OOP Supplier and Energy should implement a usable interface which provides this function. This guarantees,
     * that outside functionality can ask for an entities usable state in a uniform way.
     * @param {Array} endEnergies A selection of endEnergies in the context to check the entities usable state in.
     * @param {Array} neededEnergies A selection of neededEnergies in the context to check the entities usable state in.
     * @param {Array} suppliers A selection of suppliers in the context to check the entities usable state in.
     * @returns {Boolean} if the entity is usable in the given context.
     */
    // eslint-disable-next-line class-methods-use-this, no-unused-vars
    isUsable (endEnergies, neededEnergies, suppliers) {
        return true;
    }

    /**
     * Serializes an entity to make it fitting for API requests, especially RUDI API requests.
     */
    // eslint-disable-next-line class-methods-use-this
    serialize () {
        return {
            id: this.id,
            name: this.name,
            color: this.color,
        };
    }

    static deserialize (object) {
        return new this(object.id, object.name, object.color);
    }
}

export default Entity;