import Energy from '../Energy';

class NeededEnergy extends Energy {
    description = '';
    values;

    constructor () {
        super(...arguments);
        this.id = isNaN(this.id) ? this.id : parseInt(this.id);

        this.demandBool = true;
    }

    isUsable (endEnergies, neededEnergies, suppliers) {
        const allSupplierOutputs = suppliers.map(sup => sup.output).map(supOutputs => supOutputs.map(output => output.energy.id)).flat();
        return allSupplierOutputs.includes(this.id)
    }

    serialize () {
        let serialized = super.serialize();
        serialized = {
            ...serialized,
            refInputData: `${this.id}`
        };
        typeof this.values !== 'undefined' && (serialized.values = this.values);

        return serialized;
    }

    static deserialize (object) {
        const energy = super.deserialize(...arguments);
        energy.description = object.description;
        energy.values = object.values;

        return energy;
    }
}

export default NeededEnergy;