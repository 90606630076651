import ClimateData from './Steps/ClimateData'
import BuildingTypes from './Steps/BuildingTypes'
import BuildingsEnergies from './Steps/BuildingsEnergies'
import ScenarioOptions from './Steps/ScenarioOptions'
import ScenarioResults from './Steps/ScenarioResults'
import StepValidationError from './Steps/Errors/StepValidationError'
import StepExecutionError from './Steps/Errors/StepExecutionError'

class Workflow {
    constructor (steps) {
        this.stepOrder = steps
    }

    /**
     * Executes the step with the given index and all steps that preceded this step.
     * @param {number} index The index of the step.
     * @param {object} store The store object that should be used as storage for step data.
     */
    async executeStep (index, store) {
        const relevantSteps = this._getStepsTo(index)

        let i = 0
        for (const { executed } of relevantSteps) {
            executed || await this.executeSingleStep(i, store)
            i++
        }
    }

    async executeSingleStep (index, store) {
        const stepClass = this._getStep(index)
        stepClass.store = store

        if (stepClass.validate()) {
            try {
                await stepClass.execute()
                const step = this._getStep(index)
                step.executed = true
            } catch (error) {
                const e = new StepExecutionError(error, stepClass.name)
                console.error(e)
                throw e
            }
        } else {
            const e = new StepValidationError(`Validation for step ${index + 1} failed. Some data is missing. No further steps will be executed.`)
            console.warn(e)
            throw e
        }
    }

    validateStep (index, store) {
        const relevantSteps = this._getStepsTo(index)
        return relevantSteps.every((state, i) => this.validateSingleStep(i, store))
    }

    /**
     * Validates a specific set of steps indicated by their step order index (0 to n).
     * @param {array<number>} indexes The array of indexes to check
     * @param {object} store The store
     * @returns If all the given steps were valid
     */
    validateSpecificSteps (indexes, store) {
        return indexes.every(index => this.validateSingleStep(index, store))
    }

    validateSingleStep (index, store) {
        const stepClass = this._getStep(index)
        stepClass.store = store
        return stepClass.validate()
    }

    /**
     * Invalidates the step with the given index and all steps that follow after it.
     * @param {number} index The step to start the validation from
     * @param {object} store The store
     * @param {any} params Parameters passed to step.invalidate
     */
    invalidateStep (index, store, params) {
        const relevantSteps = this._getStepsFrom(index)
        relevantSteps.forEach((step, i) => this.invalidateSingleStep(index + i, store, params))
    }

    invalidateSingleStep (index, store, params) {
        const stepClass = this._getStep(index)
        stepClass.store = store
        stepClass.invalidate(params)
        const step = this._getStep(index)
        step.executed = false
    }

    /**
     * Returns if step needs execution
     * @param {Number} stepId - StepIndex/-ID
     * @returns {boolean}
     */
    stepNeedsExecution (stepId) {
        const step = this._getStep(stepId)
        return step && !step.executed
    }

    _getStep (index) {
        return this.stepOrder[index]
    }

    _getStepsTo (index) {
        return this.stepOrder.slice(0, index + 1)
    }

    _getStepsFrom (index) {
        return this.stepOrder.slice(index)
    }
}

const BRUIWorkflow = new Workflow([new ClimateData(), new BuildingTypes(), new BuildingsEnergies(), new ScenarioOptions(), new ScenarioResults()])
export { BRUIWorkflow, Workflow }