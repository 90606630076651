<template>
    <div class="store-translator" hidden />
</template>

<script>
import BERTAAPIConnector from '@/scripts/Connectors/BERTA/APIConnector';
import NeededEnergy from '@/scripts/Objects/Energy/NeededEnergy';

export default {
    name: 'StoreTranslate',
    data () {
        return {
            languageMap : {
                'en' : 'EN',
                'de' : 'DE',
            }
        }
    },
    computed: {
        currentLanguage () {
            return this.$store.state.language
        },
    },
    watch: {
        async currentLanguage () {
            // update all store components based on language
            // just update the energyTypes if there is data to be updated
            this.$store.state.energyTypes.length && await this.updateEnergyTypes();
            // just update the objectEnergies if there is data to be updated
            this.$store.state.objectEnergies.length && await this.translateObjectEnergies();
        }
    },
    methods: {
        /**
         * Translate all Energy Types by making a Request to the BERTA API
         * This will get a list of all Energy Types in the requested language and their corresponding IDs
         * The IDs will be used to Update all fields that need a translation
         */
        async updateEnergyTypes () {
            // get the language and the energy Types to update
            const {language, energyTypes} = this.$store.state
            // create a connection to Berta and Request the Translation
            const connector = new BERTAAPIConnector();
            const energyTypesTranslationMap = await connector.requestAllEnergytypes(this.languageMap[language]);
            // Update the objectEnergies
            const translatedEnergyTypes = energyTypes.map((untranslated) => {
                const result = energyTypesTranslationMap.find((translated) => {
                    return translated.energyTypeID === untranslated.id;
                })
                const description = result ? result.description : untranslated.description;
                const name = result ? result.energyCategory + (result.subCategory ? ` (${result.subCategory})` : '') : untranslated.name;
                const translated = new NeededEnergy(untranslated.id, name, untranslated.color, untranslated.unit)
                translated.description = description
                return translated
            })
            // set the new energyTypes
            this.$store.commit('energyTypes', translatedEnergyTypes)
        },

        /**
         * Translate all Object Energies by relying on the already translated Energy Types.
         * This will get a list of all Energy Types in the requested language and their corresponding IDs
         * The IDs will be used to Update all fields that need a translation.
         */
        async translateObjectEnergies () {
            // get the language and the objectEnergies to update
            const {objectEnergies} = this.$store.state
            // create a connection to Berta and Request the Translation
            const {energyTypes} = this.$store.state;
            // Update the objectEnergies
            const translatedObjectEnergies = objectEnergies.map((untranslated) => {
                const result = energyTypes.find((translated) => {
                    return translated.id === untranslated.id;
                })
                const name = result ? result.name : untranslated.name;
                const description = result ? result.description : untranslated.description;
                const translated = NeededEnergy.deserialize(untranslated.serialize())
                translated.name = name
                translated.description = description
                return translated
            })
            // set the new objectEnergies
            this.$store.commit('objectEnergies', translatedObjectEnergies)
        }
    }
}
</script>

<style scoped>

</style>