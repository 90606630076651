<i18n>
{
    "en": {
        "defaultLabel": "Upload file"
    },
    "de": {
        "defaultLabel": "Datei hochladen"
    }
}
</i18n>

<template>
    <v-file-input class="file-input" prepend-icon="fa-upload" :accept="accept" :label="label" @change="onFileUpload($event)" />
</template>

<script>
export default {
    props: {
        accept: {
            type: String,
            default: 'text/csv'
        },
        label: {
            type: String,
            default () {
                return this.$t('defaultLabel')
            }
        },
        /**
         * toggle output mode:
         *   false: output as UTF-8 text
         *   true: output as file
         */
        returnAsFile: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onFileUpload (file) {
            if (!file) {
                return
            }
            if (this.returnAsFile) {
                this.$emit('fileUploaded', {content: file})
            } else {
                const reader = new FileReader();
                reader.readAsText(file, 'UTF-8');
                reader.onload = (evt) => {
                    this.$emit('fileUploaded', { content: evt.target.result })
                }
                reader.onerror = function () {
                    console.error('Something went wrong while reading the uploaded file!');
                }
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.file-input {
    padding-top: 0;
    margin-top: 0;
}
</style>