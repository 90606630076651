<template>
    <div class="stepper-wrapper">
        <v-card :elevation="0" tile class="app-logo">
            <img :src="require('@/assets/images/DBI_white.png')">
        </v-card>
        <v-stepper non-linear :value="currentPageIndex + 1" class="stepper-container">
            <v-stepper-header>
                <span v-for="(step, index) in steps" :key="index">
                    <span v-if="step.valid">
                        <v-stepper-step editable :step="index + 1" color="#ef324d" @click="() => navigate(step)">
                            <span class="stepper-step-label stepper-enabled-label d-none d-lg-flex d-xl-flex d-md-none">{{ step.name }}</span>
                        </v-stepper-step>
                    </span>
                    <v-stepper-step v-else class="stepper-disabled" :step="index + 1" color="#ef324d">
                        <span class="stepper-step-label d-none d-lg-flex d-xl-flex d-md-none ">{{ step.name }}</span>
                    </v-stepper-step>
                </span>
            </v-stepper-header>
        </v-stepper>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    props: {
        steps: {
            type: Array,
            default () {
                return []
            }
        }
    },
    data () {
        return {
            preventRouting: false
        }
    },
    computed: {
        ...mapState(['currentPageIndex']),
    },
    watch: {
        currentPageIndex () {
            // to prevent redundant navigations, we only want to route when the currentPageIndex change wasn't triggered by a route itself
            this.preventRouting || this.navigate(this.steps[this.currentPageIndex])
            this.preventRouting = false
        },
        $route () {
            const currentStepIndex = this.steps.indexOf(this.steps.find(step => step.url === `/${this.$route.name}`))
            if (currentStepIndex !== this.currentPageIndex) {
                this.preventRouting = true
                this.$store.commit('currentPageIndex', currentStepIndex)
            }
        }
    },
    methods: {
        navigate (step) {
            this.$router.push(step.url)
        }
    },
}
</script>

<style lang="scss" scoped>
.stepper-wrapper {
    display: flex;
    .app-logo {
        display: inline-block;
        height: 72px;
        background: $color-text;
        padding: 11px;
        z-index: 999;
        width: 12%;

        img {
            float: left;
            min-width: 140px;
            min-height: 50px;
            max-width: 100%;
            max-height: 100%;
        }
    }

    .stepper-container {
        display: inline-block;
        background: $color-text !important;
        box-shadow: none;
        border-radius: 0px;
        width: 88%;

        a {
            text-decoration: none;
        }
    }

    .stepper-enabled-label {
        color: $color-background !important;
    }

}
</style>

<style lang="scss">
.v-stepper__header {
    gap: 6% !important;
    justify-content: center !important;

    span {
        left: -6%;
        position: relative;
    }
}

.v-stepper__step--active .stepper-step-label {
    font-weight: bold;
}

.stepper-disabled {
    .stepper-step-label {
        color: rgba(255, 255, 255, 0.54) !important;
    }
    .v-stepper__step__step {
        background: rgba(255, 255, 255, .54) !important;
        color: rgb(44, 62, 80) !important;
    }
}
</style>
