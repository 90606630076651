import EnergyPrognosis from '@/scripts/Objects/Energy/EnergyPrognosis';
import AttributeObserverProxy from '@/scripts/Objects/Interfaces/AttributeObserverProxy';
import { v4 as uuidv4 } from 'uuid'
import ObjectHelper from '@/scripts/Helper/Object';

class Building {
    id = null;
    name;
    type;
    location;
    floors;
    area;
    grossFloorArea;
    prognosis;
    areNewPrognosesNeeded = false;

    /**
     * Constructor of the Building class, that sets initial values and calculates other attributes like grossFloorArea,
     * areNewPrognosesNeeded and returns a proxy object that observes changes to the attributes of this class, so that
     * the onAttributeChanged method will be called automatically.
     *
     * @param {string} name                     The name of the building.
     * @param {string} type                     The type of the building represented as a string like "Apartment".
     *                                          Careful: This is not the typeID, which is a number, but the type name.
     *                                                   This is done, so the type can be easily translated but the
     *                                                   semantics of the typeID is still preserved.
     * @param {Object} location                 The location of the building with latitude and longitude.
     * @param {number} floors                   The number of floors of the building.
     * @param {number} area                     The area of the building in square meters per one floor.
     * @param {EnergyPrognosis} prognosis       The prognosis of the building, that contains the energy consumptions.
     * @param {Object} climateClassification    The climate classification of the building.
     * @param {number} id                       A unique identifier for the building, can be used for referencing.
     * @param {Object[]} referenceBuildings     An array of reference buildings, that can be used for the prognosis.
     * @return {AttributeObserverProxy}
     */
    constructor (name, type, location, floors = 1, area = 0, prognosis = null, climateClassification = {
        region: undefined,
        name: undefined,
        representativeMeasuringStation: undefined,
        subCategory: undefined,
    }, id = null, referenceBuildings = []) {
        this.id = id || uuidv4();
        this.name = name;
        // be careful with the type, it is not the typeID, but the type name
        // this is done, so the type can be easily translated but the semantics of the typeID is still preserved
        this.type = type;
        this.typeID = null;
        this.location = location;
        this.climateClassification = climateClassification;
        this.floors = floors || 1;
        this.area = area || 0;
        this.grossFloorArea = this._calcGrossFloorArea();
        this.prognosis = prognosis;
        this.referenceBuildings = referenceBuildings;
        this.areNewPrognosesNeeded = !this.hasPrognosis() && !this.hasCustomType();
        this.yearOfConstruction = null;
        this.buildingClasses = null;
        return new AttributeObserverProxy(this);
    }

    onAttributeChanged (attributeName, newValue, oldValue) {
        let wasReferenceBuildingSet = false;
        let hasCustomType = false;
        switch (attributeName) {
            case 'prognosis':
                this.setPrognosed()
                break;
            case 'typeID':
                hasCustomType = this.hasCustomType()
                this.areNewPrognosesNeeded = (newValue !== oldValue) && !hasCustomType;
                break
            case 'yearOfConstruction':
            case 'buildingClasses':
            case 'type':
            case 'location':
                this.areNewPrognosesNeeded = (newValue !== oldValue) && !this.hasCustomType();
                break;
            case 'area':
                this.areNewPrognosesNeeded = (newValue !== oldValue) && !this.hasCustomType();
                this.floors && this.area && (this.grossFloorArea = this._calcGrossFloorArea());
                break;
            case 'floors':
                this.areNewPrognosesNeeded = (newValue !== oldValue) && !this.hasCustomType();
                this.floors && this.area && (this.grossFloorArea = this._calcGrossFloorArea());
                break;
            case 'grossFloorArea':
                this.areNewPrognosesNeeded = (newValue !== oldValue) && !this.hasCustomType();
                this.floors && this.grossFloorArea && (this.area = this._calcArea());
                break;
            case 'referenceBuildings':
                wasReferenceBuildingSet = true;
                break;
        }
        if ((this.areNewPrognosesNeeded && !wasReferenceBuildingSet) || hasCustomType) {
            this.resetPrognoses()
        }
    }

    _calcGrossFloorArea () {
        return this.area * this.floors;
    }

    _calcArea () {
        return this.grossFloorArea / this.floors;
    }

    hasCustomType () {
        return isNaN(this.typeID) || this.typeID === -1 || this.typeID === null;
    }

    hasPrognosis () {
        return !(this.prognosis === undefined || this.prognosis === null);
    }

    usePrognosis (index) {
        // go through all reference buildings and check if they are selected
        this.referenceBuildings.forEach((referenceBuilding, refBuildingIndex) => {
            referenceBuilding.selected = refBuildingIndex === index
        })
        this.prognosis.energies = [];
    }

    hasLocation () {
        return typeof this.location !== 'undefined' && this.location !== null;
    }

    resetReferenceBuildings () {
        this.referenceBuildings = [];
    }

    resetPrognoses () {
        this.prognosis = null;
        this.referenceBuildings = [];
    }

    setLocation (city, latlng) {
        if (typeof this.location === 'undefined') {
            this.location = {};
        }

        this.location.city = city;
        this.location.lat = latlng.lat;
        this.location.lng = latlng.lng;
    }

    setPrognosed () {
        this.areNewPrognosesNeeded = false
    }
    isValid () {
        return this.name && (this.typeID || this.type) && this.location && this.grossFloorArea > 0;
    }

    serialize (withPrognoses = true) {
        const {
            name,
            type,
            typeID,
            location,
            floors,
            area,
            prognosis,
            referenceBuildings,
            climateClassification,
            yearOfConstruction,
            buildingClasses,
        } = this;

        const serializedBuilding = {
            name,
            type,
            typeID,
            location,
            floors,
            area,
            climateClassification,
            yearOfConstruction,
            buildingClasses,
        };

        if (withPrognoses) {
            serializedBuilding.referenceBuildings = [];
            for (const refBuilding of referenceBuildings) {
                const refBuildingSerialized = ObjectHelper.cloneWithPrototype(refBuilding);
                if (refBuilding.prognosis) {
                    refBuildingSerialized.prognosis = refBuilding.prognosis.serialize();
                } else {
                    refBuildingSerialized.prognosis = null;
                }
                serializedBuilding.referenceBuildings.push(refBuildingSerialized);
            }
            if (prognosis) {
                serializedBuilding.prognosis = prognosis.serialize();
            }
        }
        return serializedBuilding;
    }

    static deserialize ({ name, type, typeID, location, floors, area, buildingClasses, yearOfConstruction,climateClassification, referenceBuildings, prognosis }) {
        const deserializedReferenceBuildings = [];
        let deserializedPrognosis = null;
        if (referenceBuildings) {
            for (const refBuilding of referenceBuildings) {
                const refBuildingDeserialized = ObjectHelper.cloneWithPrototype(refBuilding);
                if (refBuilding.prognosis) {
                    refBuildingDeserialized.prognosis = EnergyPrognosis.deserialize(refBuilding.prognosis);
                } else {
                    refBuildingDeserialized.prognosis = null;
                }
                deserializedReferenceBuildings.push(refBuildingDeserialized);
            }
        }
        if (prognosis) {
            deserializedPrognosis = EnergyPrognosis.deserialize(prognosis);
        }
        const building = new this(name, type, location, floors, area, null, climateClassification, null);
        building.typeID = typeID;
        if (referenceBuildings) {
            building.referenceBuildings = deserializedReferenceBuildings;
        }
        if (prognosis) {
            building.prognosis = deserializedPrognosis;
        }
        if (buildingClasses) {
            building.buildingClasses = buildingClasses;
        }
        if (yearOfConstruction) {
            building.yearOfConstruction = yearOfConstruction;
        }
        return building;
    }

}

export default Building;
