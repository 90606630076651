export default {
    objectDescriptionForm: {
        canModifyName: true,
        canModifyLocation: true
    },
    buildingsForm: {
        canAddGroups: true,
        canDeleteGroups: true,
        canDeleteBuildings: true,
        canModifyBuildings: true,
    },
    router: {
        /**
         * @type {string} mode - 'abstract' | 'hash'
         */
        mode: 'hash'
    },
    energieRequirementLoadPanel: {
        /**
         * @type {boolean|string} useStringInsteadOfSiteName - if non empty string is given, BRUI uses the string instead of site name
         */
        useStringInsteadOfSiteName: false
    }
}
