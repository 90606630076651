class ColorHelper {
    static getRandomHexColor (opacity = '22') {
        return `#${(Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0')}${opacity}`
    }

    static commaStringToHex (colorString) {
        const colorArray = colorString.split(',').map(n => parseInt(n))
        return `#${((1 << 24) + (colorArray[0] << 16) + (colorArray[1] << 8) + colorArray[2]).toString(16).substr(1)}`
    }

    static getRandomCommaRGBString () {
        return `${Math.floor(Math.random() * 255 + 1)},${Math.floor(Math.random() * 255 + 1)},${Math.floor(Math.random() * 255 + 1)}`
    }

}

export default ColorHelper