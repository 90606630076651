import AbstractStep from './AbstractStep'
import TryDataParser from '../Parser/ClimateAPI/TryDataParser'
import DWDAPIConnector from '../Connectors/DWD/APIConnector'

class ClimateData extends AbstractStep {
    name = 'ClimateData'

    // eslint-disable-next-line class-methods-use-this
    validate () {
        return true
    }

    async execute () {
        await this.saveWeatherData()
    }

    // Nothing to invalidate.
    // eslint-disable-next-line class-methods-use-this
    invalidate () {
        return true
    }

    async saveWeatherData () {
        const location = this.store.state.objectDescription.addressLatLng;
        if (!location) {
            return {}
        }
        const apiConnector = new DWDAPIConnector();

        let climateData = null
        try {
            climateData = await apiConnector.requestTRYDataByLatLng(location.lat, location.lng)
        } catch (e) {
            throw new Error(`Requesting climate data failed: ${e}`)
        }
        const climateParser = new TryDataParser(climateData, 'MESS_DATUM', ['t', 'RF', 'B', 'D', 'WG'])
        const cleanParsedClimateData = climateParser.cleanParse()
        const parsedClimateData = climateParser.parse()
        this.store.commit('climateData', { raw: climateData, data: cleanParsedClimateData, labels: parsedClimateData.labels })
        return parsedClimateData
    }
}

export default ClimateData