<i18n>
{
    "en": {
        "heading": "Application options",
        "objectDescription" : "Object and element description",
        "climateData" : "Climate data",
        "loadProfiles": "Load profiles",
        "scenarioConfigs": "Variant configuration",
        "scenarioResults": "Variant results",
        "experimental" : "experimental"
    },
    "de": {
        "heading": "Anwendungsoptionen",
        "objectDescription" : "Objekt- und Elementbeschreibung",
        "climateData" : "Klimadaten",
        "loadProfiles": "Lastgänge",
        "scenarioConfigs": "Varianten-Konfiguration",
        "scenarioResults": "Varianten-Ergebnisse",
        "experimental" : "experimentell"
    }
}
</i18n>

<template>
    <v-navigation-drawer class="options-drawer" :mini-variant.sync="collapsed" permanent>
        <v-list-item class="px-2">
            <v-list-item-icon @click="collapsed = !collapsed">
                <v-icon small>
                    {{ collapsed ? 'fa-chevron-left' : 'fa-chevron-right' }}
                </v-icon>&nbsp;
                <v-icon>fa-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('heading') }}</v-list-item-title>
        </v-list-item>
        <v-divider />
        <v-list-item link>
            <v-list-item-icon class="drawer-icon">
                <v-icon>fa-language</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-btn-toggle v-model="language" class="language-buttons">
                    <v-btn><span class="fi fi-de" />&nbsp;DE</v-btn>
                    <v-btn><span class="fi fi-gb" />&nbsp;EN</v-btn>
                </v-btn-toggle>
            </v-list-item-content>
        </v-list-item>
        <v-list-item link>
            <v-list-item-icon class="drawer-icon">
                <v-icon>fa-exchange-alt</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-btn-toggle multiple>
                    <v-btn @click="openImportForm = !openImportForm">
                        <v-icon>fa-upload</v-icon>&nbsp;Import
                    </v-btn>
                    <v-btn @click="openExportForm = !openExportForm">
                        <v-icon>fa-download</v-icon>&nbsp;Export
                    </v-btn>
                </v-btn-toggle>
            </v-list-item-content>
        </v-list-item>

        <v-dialog v-model="openExportForm" max-width="fit-content" persistent>
            <v-card>
                <v-card-title>
                    <span class="text-h5">Daten exportieren</span>
                </v-card-title>
                <v-card-text>
                    <checkbox-selection-form
                        :options="exportOptions" @statesCreated="($event) => toExport = $event.states" @selectionChange="($event) => toExport = $event.states"
                    />
                    <v-btn color="scondary" @click="openExportForm = !openExportForm">
                        {{ $t('common.buttons.close') }}
                    </v-btn>&nbsp;
                    <v-btn color="primary" @click="openExportForm = !openExportForm; exportStoreData();">
                        <v-icon>fa-download</v-icon>&nbsp;Export starten
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="openImportForm" max-width="fit-content" persistent>
            <v-card>
                <v-card-title>
                    <span class="text-h5">Daten importieren</span>
                </v-card-title>
                <v-card-text>
                    <file-upload accept="application/json" label="Import hochladen..." @fileUploaded="($event) => toImport = JSON.parse($event.content)" />
                    <v-btn @click="openImportForm = !openImportForm">
                        {{ $t('common.buttons.close') }}
                    </v-btn>&nbsp;
                    <v-btn color="primary" @click="openImportForm = !openImportForm; importStoreData();">
                        <v-icon>fa-upload</v-icon>&nbsp;Import starten
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-navigation-drawer>
</template>

<script>
import CheckboxSelectionForm from './Forms/CheckboxSelectionForm.vue'
import FileUpload from './Inputs/FileUpload.vue'
import StateExporter from '../scripts/ImportExport/StateExporter'
import StateImporter from '../scripts/ImportExport/StateImporter'

export default {
    components: {
        CheckboxSelectionForm,
        FileUpload
    },
    data () {
        return {
            collapsed: true,
            language: process.env.VUE_APP_I18N_LOCALE,
            languageMap: ['de', 'en'],
            openExportForm: false,
            openImportForm: false,
            toExport: {},
            toImport: {}
        }
    },
    computed: {
        exportOptions () {
            return [
                { id: 'objectDescription', name: this.$t('objectDescription'), disabled: !!!this.$store.state.buildingGroups.length, initialState: false },
                { id: 'climateData', name: this.$t('climateData'), disabled: !!!Object.keys(this.$store.state.climateData).length, initialState: false },
                {
                    id: 'loadProfiles', name: `${this.$t('loadProfiles')} (${this.$t('experimental')})`,
                    disabled: !this.$store.state.buildingGroups.some(({ data }) => data.some(building => building.hasPrognosis())) && !this.$store.state.objectEnergies.length,
                    initialState: false, labelIcon: 'fa-flask'
                },
                { id: 'scenarioConfigs', name: `${this.$t('scenarioConfigs')} (${this.$t('experimental')})`, disabled: !!!this.$store.state.scenarios.length, initialState: false, labelIcon: 'fa-flask' },
                { id: 'scenarioResults', name: `${this.$t('scenarioResults')} (${this.$t('experimental')})`, disabled: !this.$store.state.scenarios.some(s => s.result), initialState: false, labelIcon: 'fa-flask' },
            ]
        }
    },
    watch: {
        language () {
            this.$root.$i18n.locale = this.languageMap[this.language]
            this.$store.commit('languageChange', this.$root.$i18n.locale)
        }
    },
    methods: {
        exportStoreData () {
            const exportedObject = StateExporter.exportState(this.$store.state, Object.keys(this.toExport).filter(key => this.toExport[key]))

            const blob = new Blob([JSON.stringify(exportedObject, null, 2)], { type: 'application/json' })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = `export_${new Date().getTime()}`
            link.click()
        },
        importStoreData () {
            // check if the imported data has a language property, if so, switch it
            // this is done before the import, so that the language is changed before the store is updated
            // therefore no changes in the data is required on import
            if (this.toImport.language) {
                this.language = this.languageMap.indexOf(this.toImport.language)
            }
            StateImporter.importState(this.$store, this.toImport)
        }
    }
}
</script>

<style lang="scss" scoped>
.options-drawer {
    height: fit-content !important;
    width: fit-content !important;
    background-color: white !important;
    border: 1px solid #eee;
    border-radius: 4px;
    padding: 5px;

    .language-buttons {
        display: flex;

        button {
            flex-grow: 1;
        }
    }

    .drawer-icon {
        margin-top: 24px;
        max-width: 25px;
    }
}
</style>