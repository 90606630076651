import Entity from './Entity';
import Energy from './Energy';
import WithCustomAttributes from './Interfaces/WithCustomAttributes';
import NumberUnit from './NumberUnit';

class System extends Entity /* implements WithCustomAttributes */ {
    coefficientOfPerformance;
    costsInvestPerUnit;
    costsInvestAltPerYearPercentage;
    costsOperateAndMaintenanceAbsolute;
    costsMaintenanceAltPerYearPercentage;

    costsMaintenancePercentage;
    costsStructure;
    deprecationPeriod;
    dimensionPerUnit;
    relation;
    minDimension;
    maxDimension;
    input = [];
    output = [];
    statDependency;
    inputData;
    _inputRelation;
    _outputRelation;
    _maxInputEnergies = 1;
    _maxOutputEnergies = 1;

    setInputRelation (relation = 'AND') {
        if (this.input.length === 2) {
            this._inputRelation = relation
        } else {
            console.warn('System input relation can only be set when two energies are set.')
        }
    }

    setOutputRelation (relation = 'AND') {
        if (this.output.length === 2) {
            this._outputRelation = relation
        } else {
            console.warn('System output relation can only be set when two energies are set.')
        }
    }

    getInputRelation () {
        if (this._inputRelation === undefined) {
            return 'AND'
        }
        return this._inputRelation
    }

    getOutputRelation () {
        if (this._outputRelation === undefined) {
            return 'AND'
        }
        return this._outputRelation
    }

    addInputEnergy (energy, factor = 1, additionalCosts = 0, index = null) {
        if (this.input.length < this._maxInputEnergies) {
            const input = additionalCosts ? { factor, energy, additionalCosts } : { factor, energy }
            if (index !== null) {
                this.input.splice(index, 0, input)
            } else {
                this.input.push(input)
            }
        }
    }

    /**
     * A Method that returns the name(s) of the input energy(s) of the system as an array.
     *
     * @return {*[]}
     */
    getInputNames () {
        if (this.input === undefined) {
            return []
        }
        return this.input.map(input => input.energy.name)
    }

    /**
     * Adds an object liertal, including the passed energy and the passed information, to the suppliers output array. A supplier can only have 2 outputs.
     * @param {Energy} energy The energy object (NeededEnergy or EndEnergy) added to the energy property of the literal
     * @param {number} [factor=1] A factor of how much energy is outputted in comparison to the other energies.
     * @param {number} [additionalCosts=0] Additional money costs of the energy.
     * @param {number} [index=null] The index in the energy array where the energy should be added to. If null, the energy will be added to the last position.
     */
    addOutputEnergy (energy, factor = 1, additionalCosts = 0, index = null) {
        if (this.output.length < this._maxOutputEnergies) {
            const output = additionalCosts ? { factor, energy, additionalCosts } : { factor, energy }
            if (index !== null) {
                this.output.splice(index, 0, output)
            } else {
                this.output.push(output)
            }
        }
    }

    /**
     * A Method that returns the name(s) of the output energy(s) of the system as an array.
     *
     * @return {*[]}
     */
    getOutputNames () {
        if (this.output === undefined) {
            return []
        }
        return this.output.map(input => input.energy.name)
    }

    replaceInputAtIndex (index, inputObject) {
        this.input.splice(index, 1, inputObject)
    }

    replaceOutputAtIndex (index, outputObject) {
        this.output.splice(index, 1, outputObject)
    }

    removeInputEnergyAtIndex (index) {
        this.input.splice(index, 1)
        if (this.input.length < 2) {
            this._inputRelation = undefined
        }
    }

    removeOutputEnergyAtIndex (index) {
        this.output.splice(index, 1)
        if (this.output.length < 2) {
            this._outputRelation = undefined
        }
    }

    canAddFurtherInputs () {
        return this.input.length < this._maxInputEnergies
    }

    canAddFurtherOutputs () {
        return this.output.length < this._maxOutputEnergies
    }

    /* interface method */
    addCustomAttribute () {
        return WithCustomAttributes.addCustomAttribute(this, ...arguments)
    }

    /* interface method */
    removeCustomAttributeById () {
        return WithCustomAttributes.removeCustomAttributeById(this, ...arguments)
    }

    correctCustomAttributeLocation () {
        return WithCustomAttributes.correctCustomAttributeLocation(this, ...arguments)
    }

    /* interface method */
    getCustomAttribute () {
        return WithCustomAttributes.getCustomAttribute(this, ...arguments)
    }

    isUsable (endEnergies, neededEnergies, suppliers) {
        const allSupplierOutputs = suppliers.map(sup => sup.output).map(supOutputs => supOutputs.map(output => output.energy.id)).flat();
        const allSupplierInputs = suppliers.map(sup => sup.input).map(supInputs => supInputs.map(input => input.energy.id)).flat();

        // Supplier that have missing end energies or not available needed energies (Nutzenergien) as input, are not usable
        return this.input.every(input => endEnergies.find(e => e.id === input.energy.id) || allSupplierOutputs.includes(input.energy.id))
            && this.output.every(output => neededEnergies.find(e => e.id === output.energy.id) || allSupplierInputs.includes(output.energy.id));
    }

    serialize () {
        let serialized = super.serialize();
        serialized = {
            ...serialized,
            coefficientOfPerformance: this.coefficientOfPerformance,
            costsInvestPerUnit: this.costsInvestPerUnit.serialize(),
            costsMaintenancePercentage: this.costsMaintenancePercentage.serialize(),
            costsStructure: this.costsStructure,
            dimensionPerUnit: this.dimensionPerUnit.serialize(),
            relation: this.relation,
            deprecationPeriod: this.deprecationPeriod.serialize(),
            costsInvestAltPerYearPercentage: this.costsInvestAltPerYearPercentage.serialize(),
            costsOperateAndMaintenanceAbsolute: this.costsOperateAndMaintenanceAbsolute.serialize(),
            costsMaintenanceAltPerYearPercentage: this.costsMaintenanceAltPerYearPercentage.serialize(),
            inputRelation: this.input.length === 2 && this._inputRelation ? `{${this.input[0].energy.id}} ${this._inputRelation} {${this.input[1].energy.id}}` : undefined,
            input: this.input.reduce((obj, item, index) =>
                Object.assign(obj, { [index + 1]: {
                    factor: item.factor,
                    additionalCosts: typeof item.additionalCosts === 'undefined' ? undefined : (item.additionalCosts || 0),
                    refEnergy: `${item.energy.id}`,
                    unit: item.energy.unit }
                }), {}),
            outputRelation: this.output.length === 2 && this._outputRelation ? `{${this.output[0].energy.id}} ${this._outputRelation} {${this.output[1].energy.id}}` : undefined,
            output: this.output.reduce((obj, item, index) =>
                Object.assign(obj, { [index + 1]: {
                    factor: item.factor,
                    additionalCosts: typeof item.additionalCosts === 'undefined' ? undefined : (item.additionalCosts || 0),
                    refEnergy: `${item.energy.id}`,
                    unit: item.energy.unit }
                }), {}),
        };

        // only include min- and maxDimension if set
        if (this.minDimension || this.minDimension === 0) {
            serialized.minDimension = this.minDimension.serialize()
        }
        if (this.maxDimension || this.maxDimension === 0) {
            serialized.maxDimension = this.maxDimension.serialize()
        }

        this.statDependency && (serialized.statDependency = this.statDependency.reduce((obj, item) =>
            Object.assign(obj, { [item.id]: { id: item.id, unit: item.unit, value: item.value, _displayName: item.name } }), {}));
        this.inputData && (serialized.inputData = this.inputData.reduce((obj, item) =>
            Object.assign(obj, { [item.id]: { id: item.id, unit: item.unit, value: item.value, _displayName: item.name } }), {}));

        return serialized;
    }

    static deserialize (object, energies = null) {
        const system = super.deserialize(...arguments);
        system.coefficientOfPerformance = NumberUnit.deserialize(object.coefficientOfPerformance);
        system.costsInvestPerUnit = NumberUnit.deserialize(object.costsInvestPerUnit);
        system.costsMaintenancePercentage = NumberUnit.deserialize(object.costsMaintenancePercentage);
        system.costsStructure = object.costsStructure;
        system.dimensionPerUnit = NumberUnit.deserialize(object.dimensionPerUnit);
        system.relation = object.relation;
        system.minDimension = NumberUnit.deserialize(object.minDimension);
        system.maxDimension = NumberUnit.deserialize(object.maxDimension);
        system.deprecationPeriod = NumberUnit.deserialize(object.deprecationPeriod)
        system.costsInvestAltPerYearPercentage = NumberUnit.deserialize(object.costsInvestAltPerYearPercentage)
        system.costsOperateAndMaintenanceAbsolute = NumberUnit.deserialize(object.costsOperateAndMaintenanceAbsolute)
        system.costsMaintenanceAltPerYearPercentage = NumberUnit.deserialize(object.costsMaintenanceAltPerYearPercentage)

        object.input && Object.entries(object.input).forEach(([,value]) => {
            let energy = new Energy(value.refEnergy);
            if (energies) {
                energy = energies.find(e => `${e.id}` === value.refEnergy)
            }
            system.addInputEnergy(energy, value.factor, value.additionalCosts)
        })

        if (system.input.length > 1) {
            system.setInputRelation((object.inputRelation && object.inputRelation.includes('OR')) ? 'OR' : 'AND')
        }

        // Parse all the output Data and map to the objects
        object.output && Object.entries(object.output).forEach(([,value]) => {
            let energy = new Energy(value.refEnergy);
            if (energies) {
                const foundEnergy = energies.find(e => `${e.id}` === value.refEnergy)
                foundEnergy && (energy = foundEnergy)
            }
            system.addOutputEnergy(energy, value.factor, value.additionalCosts)
        })

        if (system.output.length > 1) {
            system.setOutputRelation((object.outputRelation && object.outputRelation.includes('OR')) ? 'OR' : 'AND')
        }

        // Parse all the statDependency Data and map to the objects
        Object.entries({ ...object.statDependency, ...object.inputData }).forEach(([key, value]) => {
            system.addCustomAttribute(key, value.value, value.unit, value._displayName)
        });

        return system;
    }
}

export default System;