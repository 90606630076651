class StepExecutionError extends Error {
    type = 'execution'
    message

    constructor (error, step) {
        super(error)
        this.message = `Something went wrong while executing step "${step}"`
        this.name = 'StepExecutionError'
        this.originalError = error
        this.stack = `${this.name}: ${this.message}\n> Original error stack: ${error.stack}`
    }
}

export default StepExecutionError