import AbstractStep from './AbstractStep'
import BERTAAPIConnector from '../Connectors/BERTA/APIConnector'
import ColorHelper from '../Helper/Color'
import UnitConverter from '../Helper/UnitConverter'
import NeededEnergy from '../Objects/Energy/NeededEnergy'
import ArrayHelper from '@/scripts/Helper/Array';
import EnergyPrognosis from '@/scripts/Objects/Energy/EnergyPrognosis';
import ObjectHelper from '@/scripts/Helper/Object';
import Building from '@/scripts/Objects/Building';

class BuildingsEnergies extends AbstractStep {
    name = 'BuildingEnergies'
    sumUpBuildingDataProperties = ['area', 'floors']

    allBuildings;

    isOnePrognosisNeeded = false;

    energyTypesRequested = false;

    // eslint-disable-next-line class-methods-use-this
    validate () {
        return this._doesWeatherDataExist() && this._doBuildingsExistAndAreValid()
    }

    async execute () {
        this.saveGroupSums()

        // get all buildings that can be prognosed by Berta
        this.allBuildings = (this.store.getters.allBuildings || [])
            .filter(building => !building.hasCustomType());
        this.isOnePrognosisNeeded = this.allBuildings.some(building => building.areNewPrognosesNeeded)

        if (!this.energyTypesRequested) {
            await this._saveNeededEnergies()
            this.energyTypesRequested = true
        }
        // request all modelIDs for all buildings and set the best model if no model is set yet
        await this._requestAndSaveModelsForBuildings()
        // request all loadProfiles for all buildings and set the loadProfiles
        await this._requestAndSaveLoadProfilesForBuildings()

        this.saveBuildingsSums()
    }

    /**
     * Normally we dont need to invalidate anything, because saveChangedBuildingEnergies detect changes. But sometimes we might want to reset data and calculate them newly.
     * @param {boolean} clearBuildingRefBuildings If true, RefBuildings and the selected Prognosis gets emptied in the store. Everything will be recalculated.
     * @returns true
     */
    invalidate (clearBuildingRefBuildings = false) {
        clearBuildingRefBuildings && this.store.commit('clearAllBuildingRefBuildings')
        return true
    }

    /**
     * Request all possible Energy Types that are known to Berta and saves them to the store.
     * @return {Promise<void>}
     */
    async _saveNeededEnergies () {
        if (!this.isOnePrognosisNeeded && this.store.state.energyTypes.length !== 0) {
            // if no prognosis is needed, the energyTypes dont need to be updated
            return;
        }

        const languageMap = {
            'en': 'EN',
            'de': 'DE'
        }
        const apiConnector = new BERTAAPIConnector()
        const allEnergyTypes = await apiConnector.requestAllEnergytypes(languageMap[this.store.state.language] || 'DE')
        const neededEnergies = []

        allEnergyTypes.forEach(energyType => {
            let unit = energyType.energyCategoryUnit
            if (unit === 'W') {
                unit = UnitConverter.convertPowerUnit(unit, 1000)
            }
            const energy = new NeededEnergy(energyType.energyTypeID, this._buildEnergyTypeDisplayName(energyType), ColorHelper.getRandomCommaRGBString(), unit)
            energy.description = energyType.description
            neededEnergies.push(energy)
        })

        this.store.commit('energyTypes', neededEnergies)
    }

    saveBuildingsSums () {
        // dont use this.allBuildings, because we want to save the sums for all buildings,
        // even if they are not prognosed by Berta
        const buildings = (this.store.getters.allBuildings || []);

        const energyPrognoses = buildings
            .map((building) => building.prognosis)
            .filter((prognosis) => prognosis !== null)

        const energySum = {};
        energyPrognoses.forEach((prognosis) => {
            prognosis.energies.forEach(energy => {
                const energyID = energy.id;
                const energyData = energy.values;
                if (!energySum.hasOwnProperty(energyID)) {
                    energySum[energyID] = []
                }
                if (energySum[energyID].length > 0) {
                    energySum[energyID] = energySum[energyID].map((el, index) => el + energyData[index])
                } else {
                    energySum[energyID] = energyData
                }
            })
        })

        const energies = [];
        Object.keys(energySum).forEach((energyID) => {
            const energyData = energySum[energyID];
            const neededEnergy = ObjectHelper.cloneWithPrototype(this._getNeededEnergyByID(energyID));
            neededEnergy.values = energyData;
            energies.push(neededEnergy)
        })


        this.store.commit('objectEnergies', energies)
    }

    /**
     * Calculates the sums of group properties and commits them into the store.
     */
    saveGroupSums () {
        let result;
        this.store.state.buildingGroups && this.store.state.buildingGroups.length > 0 && (result = this._sumUpBuildingData());
        result && this.store.commit('objectSums', { ... result });
    }

    /**
     * Returns an object with sums for defined fields in this.sumUpBuildingDataProperties for given buildinggroups and buildings
     * @returns {{}} - Sum object
     * @private
     */
    _sumUpBuildingData () {
        const buildings = (this.store.getters.allBuildings || []);
        const sums = {};
        this.sumUpBuildingDataProperties.forEach(sumField => {
            const sumName = `sum${sumField.charAt(0).toUpperCase()}${sumField.slice(1)}`;
            buildings.forEach(building => {
                if (building[sumField] && !isNaN(building[sumField])) {
                    isNaN(sums[sumName]) && (sums[sumName] = 0);
                    sums[sumName] += building[sumField];
                }
            })
        });
        return sums;
    }

    // eslint-disable-next-line class-methods-use-this
    _buildEnergyTypeDisplayName (energyType) {
        return energyType.energyCategory + (energyType.subCategory ? ` (${energyType.subCategory})` : '')
    }

    _doesWeatherDataExist () {
        return typeof this.store.state.climateData.data === 'object' && Array.isArray(this.store.state.climateData.labels)
    }
    _doBuildingsExistAndAreValid () {
        const flatBuildings = (this.store.getters.allBuildings || [])
        const doBuildingsExist = flatBuildings.length > 0
        const areBuildingsValid = flatBuildings.every(building => building.isValid())
        return doBuildingsExist && areBuildingsValid
    }

    /**
     * Returns all the buildings of the store as an flattened array
     *
     * @return {Array.<Building>}
     * @private
     */
    async _requestAndSaveModelsForBuildings () {
        // if at least OneBuilding needs a prognosis, all Buildings with prognosis === null will also need a prognosis
        // this happens when a Site Prognosis was set and then an attribute was changed
        if (this.isOnePrognosisNeeded) {
            this.allBuildings.forEach(building => {
                if (building.prognosis === null || building.prognosis.energies.length === 0) {
                    building.areNewPrognosesNeeded = true
                }
            })
        }

        for (let i = 0; i < this.allBuildings.length; i++) {
            const building = this.allBuildings[i];

            if (!building.areNewPrognosesNeeded) {
                continue
            }
            const referenceBuildings = await this._requestPossibleReferenceBuildings(building);
            referenceBuildings[0].selected = true;
            this.store.commit('updateBuilding', { building, data : {referenceBuildings}});
        }
    }


    async _requestAndSaveLoadProfilesForBuildings () {
        for (let i = 0; i < this.allBuildings.length; i++) {
            const building = this.allBuildings[i];

            if (building.prognosis !== null && !building.areNewPrognosesNeeded && building.prognosis.energies.length > 0) {
                continue
            }

            const selectedReferenceBuilding = building.referenceBuildings.find(refBuilding => refBuilding.selected);
            // if no reference building is selected was found, skip
            if (!selectedReferenceBuilding) {
                continue
            }

            // if the prognosis is null, calculate it
            if (selectedReferenceBuilding.prognosis === null) {
                selectedReferenceBuilding.prognosis = await this._requestLoadProfileForBuilding(building, selectedReferenceBuilding)
            }

            // clone the prognosis, because its the prognosis for the current building
            // if the prognosis will be changed, it wont effect the ref Buildings.
            // For example if the User want to scale the prognosis, the ref building prognosis will not be changed
            const prognosisBuilding = selectedReferenceBuilding.prognosis.building
            const prognosisData = ObjectHelper.cloneWithPrototype(selectedReferenceBuilding.prognosis.energies)
            const clonedPrognosis = new EnergyPrognosis(prognosisBuilding, prognosisData, true)
            this.store.dispatch('setBuildingPrognosis', {
                buildingId: building.id,
                prognosis: clonedPrognosis
            })
        }
    }

    async _requestPossibleReferenceBuildings (building) {
        let buildingData = this._populateBuildingTemplate(building)
        buildingData = {buildings : [buildingData]}
        const bertaConnector = new BERTAAPIConnector();
        const possibleEnergyModelsForBuilding = await bertaConnector.requestAvailableEnergyModelsForBuilding(buildingData);
        const referenceBuildings = []
        possibleEnergyModelsForBuilding.result.forEach((possibleEnergyModel) => {
            referenceBuildings.push(this._extractBuildingInformationFromResponse(possibleEnergyModel))
        });
        return referenceBuildings
    }

    // eslint-disable-next-line class-methods-use-this
    _extractBuildingInformationFromResponse (resultBuilding) {
        const buildingData = {
            id : resultBuilding.buildingID,
            selected: false,
            area : resultBuilding.buildingArea,
            ranking : resultBuilding.buildingRanking,
            score : resultBuilding.buildingScore,
            climateClassification: resultBuilding.location.climaticClassification,
            energies: {},
            prognosis: null
        }

        Object.values(resultBuilding.energies).forEach(energy => {
            const [model] = energy.models;
            buildingData.energies[energy.energyType.energyTypeID] = {
                id : energy.energyType.energyTypeID,
                concreteEnergyLevels : energy.concreteEnergyLevels,
                timeFrame : energy.timeFrame,
                model : {
                    id : model.modelID,
                    rank: model.modelRanking,
                    fullUsageHours : model.fullUseHours,
                    inputParameters : ArrayHelper.parseFromString(model.inputParameters),
                    maxTarget : model.maxTargetValueFromTrainedModel,
                    minTarget : model.minTargetValueFromTrainedModel,
                },
                type : {
                    category : energy.energyType.energyCategory,
                    description : energy.energyType.description,
                    unit : energy.energyType.energyCategoryUnit,
                    higherEqual : energy.energyType.energyLevelHigherEqual,
                    lowerEqual : energy.energyType.energyLevelLowerEqual,
                    subCategory : energy.energyType.subCategory,
                    subCategoryUnit : energy.energyType.subCategoryUnit,
                },
            }
        })
        return buildingData;
    }

    // eslint-disable-next-line class-methods-use-this
    _populateBuildingTemplate (building) {
        return {
            type: building.typeID,
            name : building.name,
            area : {
                value : building._calcGrossFloorArea(),
                unit : 'm2'
            },
            location : {
                lat : building.location.lat,
                long : building.location.lng
            },
        }
    }

    async _requestLoadProfileForBuilding (building, referenceBuilding) {
        const buildingData = this._populateBuildingTemplate(building)
        const {climateData} = this.store.state
        const bertaConnector = new BERTAAPIConnector();
        const prognoses = []
        const requests = []

        for (const energyTypeID in referenceBuilding.energies) {
            const energy = referenceBuilding.energies[energyTypeID]
            const modelID = energy.model.id
            const request = bertaConnector.getLoadProfile(buildingData, modelID, climateData, climateData.labels)
            requests.push(request)

        }

        const responses = await Promise.all(requests)
        for (let i = 0; i < responses.length; i++) {
            const response = responses[i]
            const energyTypeID = Object.keys(referenceBuilding.energies)[i]

            const correspondingNeededEnergy = ObjectHelper.cloneWithPrototype(this._getNeededEnergyByID(energyTypeID))
            correspondingNeededEnergy.values = response.values.values
            prognoses.push(correspondingNeededEnergy)
        }

        const buildingForPrognoses = new Building(building.name, null, building.location, building.floors, building.area, null, building.climateClassification)
        return new EnergyPrognosis(buildingForPrognoses, prognoses, false);

    }

    _getNeededEnergyByID (energyID) {
        const neededEnergies = this.store.state.energyTypes;
        return neededEnergies.find(energy => `${energy.id}` === `${energyID}`);
    }
}

export default BuildingsEnergies