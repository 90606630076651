import '@fortawesome/fontawesome-free/css/all.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'fa'
    },
    theme: {
        options: { customProperties: true },
        themes: {
            light: {
                primary: '#44546a',
                marked: '#d1a3a5',
                success: '#bdd01a',
                error: '#ea5a52'
            },
        },
    },
});
