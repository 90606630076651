class StepValidationError extends Error {
    type = 'validation'
    message

    constructor (message) {
        super(message)
        this.message = message
    }
}

export default StepValidationError