class APIError extends Error {
    type = 'api'
    message
    localizedText

    /**
     * Create a new API Error instance
     * @param {String} message should contain the APIs error message
     * @param {*} fileName inherited from default Error, not currently in use
     * @param {*} lineNumber inherited from default Error, not currently in use
     * @param {String} localizedText should contain the localized error message displayed to the user
     */
    constructor (message, fileName = null, lineNumber = null, localizedText) {
        super(message)
        this.message = message
        this.localizedText = localizedText
        this.fileName = fileName
        this.lineNumber = lineNumber
    }
}

export default APIError