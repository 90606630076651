class AbstractStep {
    store
    executed = false

    constructor (store) {
        this.store = store
    }

    /**
     * A function that validates if all data, that is needed for the step is present. Should return a boolean.
     */
    // eslint-disable-next-line class-methods-use-this
    validate () {
        throw new Error('[STEP ERROR]', 'You have to implement this function')
    }

    /**
     * The execution logic of the step. Should save data into the steps store, if neccessary.
     */
    // eslint-disable-next-line class-methods-use-this
    execute () {
        throw new Error('[STEP ERROR]', 'You have to implement this function')
    }

    /**
     * Logic that must be executed when the results of a step should be invalidated. E.g.: Roll back everything that was save into the store in execute().
     */
    // eslint-disable-next-line class-methods-use-this
    invalidate () {
        throw new Error('[STEP ERROR]', 'You have to implement this function')
    }
}

export default AbstractStep