class ArrayHelper {
    /**
     * Gets the next possible currentIndex + n index in the array, that still is inside the bounds of the passed array.
     * e.g.: An array with lenght of 2, currentIndex = 1 and n = 1 will equal 1, since 1 is the last index in bounds.
     * e.g.: An array with lenght of 2, currentIndex = 0 and n = -1 will equal 0, since -1 wouldnt be a valid index.
     * @param {Array} array The array whichs bounds should be used.
     * @param {number} currentIndex The origin/current index as an integer.
     * @param {number} n The amount how much the new index should differ from the current index as an integer.
     * @returns {number}
     */
    static getIndexInBounds (array, currentIndex, n = 1) {
        let newIndex = currentIndex + n
        newIndex < 0 && (newIndex = 0)
        newIndex >= array.length && (newIndex = array.length - 1)

        return newIndex
    }

    /**
     * Splices an item out of an array. Similar to splice, but splice only works with indices.
     * @param {Array} array The array to splice from. This array will be modified.
     * @param {any} item The item to splice, must be inside the array.
     * @param {number} deleteCount see Array.prototype.splice deleteCount param
     * @param {any} replacer The item that should replce the spliced item(s), if null, no item will replace the spliced item(s)
     * @param {Function} findFn The function used to find the item inside the given array. Default is finding the item by the === operator.
     * @param {number} offset Is added to the first param of splice, can offset the inserted/replaced item index by the amount given
     */
    static spliceItem (array, item, deleteCount = 1, replacer = null, findFn = (i) => i === item, offset = 0) {
        const itemIndex = array.findIndex(findFn)

        if (replacer !== null) {
            array.splice(itemIndex + offset, deleteCount, replacer)
        } else {
            array.splice(itemIndex + offset, deleteCount)
        }
    }

    static areEqual (array1, array2) {
        if (array1.length === array2.length) {
            return array1.every((element) => array2.includes(element));
        } else {
            return false;
        }
    }

    /**
     * Parses a "["dataA", "dataB", "dataC"]" string into an array like ["dataA", "dataB", "dataC"].
     *
     * @param {String[]} stringArray    The string to parse.
     * @return [String]
     */
    static parseFromString (stringArray) {
        return stringArray
            .replace(/[\[\]"]+/g, '')
            .split(',')
            .map(tag => tag.trim())
    }
}

export default ArrayHelper