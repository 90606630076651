import CustomAttribute from '../CustomAttribute'

/**
 * Java-like "interface". Provides default implemenatations for some functions. Because JS doesn't have something like this, we need to do some things to get this to work.
 * - Implement the same functions in the class where the inface should be implemented
 * - Call the static functions of this interface in the object with this as the first param, e.g.: WithCustomAttributes.myFunction(this, ...arguments)
 *
 * Enables an entity to have functions to control their custom attributes in form of
 * - inputData: Custom Attributes with arrays as values
 * - statDependency: Custom Attributes with primitives as values
 */
class /* interface */ WithCustomAttributes {
    /* default */ static addCustomAttribute (object, id, value, unit, name = id) {
        if (Array.isArray(value)) {
            object.statDependency && object.statDependency.find(a => a.id === id) && WithCustomAttributes.removeCustomAttributeById(object, id)
            object.inputData || (object.inputData = [])
            object.inputData.push(new CustomAttribute(id, value, unit, name))
        } else {
            object.inputData && object.inputData.find(a => a.id === id) && WithCustomAttributes.removeCustomAttributeById(object, id)
            object.statDependency || (object.statDependency = [])
            object.statDependency.push(new CustomAttribute(id, value, unit, name))
        }
    }

    /* default */ static removeCustomAttributeById (object, id) {
        if (object.inputData && object.inputData.find(a => a.id === id)) {
            const index = object.inputData.findIndex(a => a.id === id)
            index > -1 && object.inputData.splice(index, 1)
        } else if (object.statDependency && object.statDependency.find(a => a.id === id)) {
            const index = object.statDependency.findIndex(a => a.id === id)
            index > -1 && object.statDependency.splice(index, 1)
        }
        object.inputData && object.inputData.length || (object.inputData = undefined)
        object.statDependency && object.statDependency.length || (object.statDependency = undefined)
    }

    /* default */ static correctCustomAttributeLocation (object, id) {
        if ((object.statDependency && object.statDependency.find(a => a.id === id) && Array.isArray(object.statDependency.find(a => a.id === id).value))
            || (object.inputData && object.inputData.find(a => a.id === id) && !Array.isArray(object.inputData.find(a => a.id === id).value))) {
            const customAttribute = WithCustomAttributes.getCustomAttribute(object, id)
            WithCustomAttributes.removeCustomAttributeById(object, id)
            WithCustomAttributes.addCustomAttribute(object, id, customAttribute.value, customAttribute.unit, customAttribute.name)
        }
    }

    /* default */ static getCustomAttribute (object, id) {
        return (object.statDependency && object.statDependency.find(a => a.id === id)) || (object.inputData && object.inputData.find(a => a.id === id))
    }
}

export default WithCustomAttributes