import System from '../System';
import Energy from '../Energy';

class Supplier extends System {
    unitMainBool;
    _maxInputEnergies = 2;
    _maxOutputEnergies = 2;

    /**
     * Adds an object liertal, including the passed energy and the passed information, to the suppliers output array. A supplier can only have 2 outputs. This function will
     * also set this.unitMainBool, if the output energy was a mainBool energy.
     * @param {Energy} energy The energy object (NeededEnergy or EndEnergy) added to the energy property of the literal
     * @param {number} [factor=1] A factor of how much energy is outputted in comparison to the other energies.
     * @param {number} [additionalCosts=0] Additional money costs of the energy.
     * @param {number} [index=null] The index in the energy array where the energy should be added to. If null, the energy will be added to the last position.
     * @param {boolean} [mainBool=true] If the energy is a mainBool. If no energy existed before, the energy will always be the mainBool.
    */
    addOutputEnergy (energy, factor = 1, additionalCosts = 0, index = null, mainBool = true) {
        if (this.output.length < this._maxOutputEnergies) {
            let mainBoolToAdd = mainBool
            this.output.length === 0 && (mainBoolToAdd = true)

            if (mainBoolToAdd) {
                this.output.forEach(o => (o.mainBool = false))
                this.unitMainBool = energy.unit
            }

            const output = additionalCosts ? { mainBool: mainBoolToAdd, factor, energy, additionalCosts } : { mainBool: mainBoolToAdd, factor, energy }
            if (index !== null) {
                this.output.splice(index, 0, output)
            } else {
                this.output.push(output)
            }
        }
    }

    serialize () {
        let serialized = super.serialize();
        serialized = {
            ...serialized,
            unitMainBool: this.unitMainBool,
            // outputs can additionally have a mainBool in suppliers
            output: this.output.reduce((obj, item, index) =>
                Object.assign(obj, { [index + 1]: {
                    factor: item.factor,
                    additionalCosts: typeof item.additionalCosts === 'undefined' ? undefined : (item.additionalCosts || 0),
                    refEnergy: `${item.energy.id}`,
                    unit: item.energy.unit,
                    mainBool: item.mainBool || false }
                }), {}),
        };

        return serialized;
    }

    static deserialize (object, energies = null) {
        const supplier = super.deserialize(...arguments);

        supplier.unitMainBool = object.unitMainBool;
        // outputs can additionally have a mainBool in suppliers
        supplier.output = []
        object.output && Object.entries(object.output).forEach(([,value]) => {
            let energy = new Energy(value.refEnergy);
            if (energies) {
                const foundEnergy = energies.find(e => `${e.id}` === value.refEnergy)
                foundEnergy && (energy = foundEnergy)
            }
            supplier.addOutputEnergy(energy, value.factor, value.additionalCosts, null, value.mainBool || false)
        })
        return supplier;
    }
}

export default Supplier;