class AbstractParser {
    labelField
    dataFields
    _data

    constructor (data = null, labelField = '', dataFields = []) {
        this.labelField = labelField
        // dataFields can be modified, when something else should be shown as a chart label. We only want a copy of this array because of this.
        this.dataFields = [ ...dataFields ]
        this._data = data
    }

    /**
     * Parses the data given to the Parser object into a chart friendly format.
     * @returns An object like: {
     *  labels: {Array} An array of labels, should have the same length as data.*.values.
     *  data: {
     *      <key>: {
     *          values: {Array} An array of values associated to the dataSet defined by key. This structure exists,
     *              so other parsers can build upon it with additional keys.
     *      },
     *      ...
     *  },
     *  metaData: {Object} An object with describing data for the labels and data given above.
     * }
     */
    parse () {
        const labels = this.extractLabels()
        const data = this.extractData()
        const metaData = this.extractMetaData()
        for (const field in data) {
            if (data[field].values.length !== labels.length) {
                throw new Error('[PARSER ERROR] [FUNCTION: parse]: Data array must be as long as the labels array')
            }
        }
        return {
            labels,
            data,
            metaData
        }
    }

    extractLabels () {
        throw new Error('[PARSER ERROR] [FUNCTION: extractLabels]', this.name, 'You have to implement the method doSomething!')
    }

    extractData (from = null) {
        const data = {}
        const dataArray = from || this._data
        dataArray.forEach(entry => {
            this.dataFields.forEach(field => {
                if (entry.hasOwnProperty(field)) {
                    if (!data[field]) {
                        data[field] = {
                            values: []
                        }
                    }
                    data[field].values.push(entry[field])
                }
            })
        })
        return data
    }

    toObject (data = null, labels = null) {
        const dataToUse = data || this.extractData()
        const labelsToUse = labels || this.extractLabels()

        const objectRepresentation = {}
        labelsToUse.forEach((label, index) => {
            const objectForLabel = {}
            for (const dataName in dataToUse) {
                objectForLabel[dataName] = dataToUse[dataName][index]
            }
            objectRepresentation[label] = objectForLabel
        })

        return objectRepresentation
    }

    extractMetaData () {
        throw new Error('[PARSER ERROR] [FUNCTION: extractMetaData]', this.name, 'You have to implement the method doSomething!')
    }
}

export default AbstractParser