class CustomAttribute {
    id;
    value;
    unit;
    name;

    constructor (id, value, unit, name = id) {
        this.id = id;
        this.value = value;
        this.unit = unit;
        this.name = name;
    }
}

export default CustomAttribute;