import System from '../System';

class Storage extends System {
    dischargeRate = 0;
    unit;

    serialize () {
        let serialized = super.serialize();
        serialized = {
            ...serialized,
            dischargeRate: this.dischargeRate,
            unit: this.unit,
        };
        // RUDI needs a mainBool for the first (and only) output in storages, because storages use the same logic as suppliers. In theory this property is obsolete, when adjusting RUDI to it.
        serialized.output['1'].mainBool = true

        return serialized;
    }

    // Disable because we still want to indicate, that energies are needed. But these are only passed to the parents function.
    // eslint-disable-next-line no-unused-vars
    static deserialize (object, energies = null) {
        const storage = super.deserialize(...arguments);

        storage.dischargeRate = object.dischargeRate;
        storage.unit = object.unit;
        return storage;
    }
}

export default Storage;