<i18n>
{
    "en": {
        "toggleAll": "Select/Deselect all"
    },
    "de": {
        "toggleAll": "Alle auswählen/abwählen"
    }
}
</i18n>

<template>
    <div>
        <v-checkbox v-if="withSelectAll" :label="$t('toggleAll')" class="font-weight-bold" @change="onToggleAll()" />
        <v-checkbox v-for="option in options" :key="option.id" v-model="checkboxStates[option.id]" :disabled="option.disabled"
            :label="option.name" :color="option.color" @change="onSelectionChange(option.id)"
        >
            <template v-if="option.labelIcon" v-slot:label>
                {{ option.name }}&nbsp;
                <v-icon :color="option.color">
                    {{ option.labelIcon }}
                </v-icon>
            </template>
        </v-checkbox>
    </div>
</template>

<script>
export default {
    props: {
        /**
         * Array of objects, one object defining one checkbox. An object can have following properties: id, name, disabled, initialState
         */
        options: {
            type: Array,
            default () {
                return []
            }
        },
        /**
         * Renders an additional checkbox at the top which gives the possibility to check/uncheck all options
         */
        withSelectAll: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            checkboxStates: this.options.reduce((obj, option) => Object.assign(obj, {[option.id]: option.initialState || false}), {}),
            allToggled: false
        }
    },
    created () {
        this.$emit('statesCreated', { states: this.checkboxStates })
    },
    methods: {
        onSelectionChange (id) {
            this.$emit('selectionChange', { states: this.checkboxStates, changed: id })
        },
        onToggleAll () {
            this.options.forEach(option => this.options.find(o => o.id === option.id).disabled || (this.checkboxStates[option.id] = !this.allToggled))
            this.$emit('selectionChange', { states: this.checkboxStates })
            this.allToggled = !this.allToggled
        }
    }
}
</script>