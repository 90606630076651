import Energy from '../Energy';
import WithCustomAttributes from '../Interfaces/WithCustomAttributes';

class EndEnergy extends Energy /* implements WithCustomAttributes */ {
    directEmission = false;
    renewable = false;
    limit;
    statDependency;
    inputData;

    constructor () {
        super(...arguments);

        this.demandBool = false;
    }

    /* interface method */
    addCustomAttribute () {
        return WithCustomAttributes.addCustomAttribute(this, ...arguments)
    }

    /* interface method */
    removeCustomAttributeById () {
        return WithCustomAttributes.removeCustomAttributeById(this, ...arguments)
    }

    correctCustomAttributeLocation () {
        return WithCustomAttributes.correctCustomAttributeLocation(this, ...arguments)
    }

    /* interface method */
    getCustomAttribute () {
        return WithCustomAttributes.getCustomAttribute(this, ...arguments)
    }

    isUsable (endEnergies, neededEnergies, suppliers) {
        const allSupplierOutputs = suppliers.map(sup => sup.output).map(supOutputs => supOutputs.map(output => output.energy.id)).flat();
        const allSupplierInputs = suppliers.map(sup => sup.input).map(supInputs => supInputs.map(input => input.energy.id)).flat();
        return [...allSupplierOutputs, ...allSupplierInputs].includes(this.id);
    }

    serialize () {
        let serialized = super.serialize();
        serialized = {
            ...serialized,
            directEmission: this.directEmission,
            renewableBool: this.renewable,
        };

        typeof this.limit !== 'undefined' && (serialized.energyLimit = this.limit);
        this.statDependency && (serialized.statDependency = this.statDependency.reduce((obj, item) =>
            Object.assign(obj, { [item.id]: { id: item.id, unit: item.unit, value: item.value, _displayName: item.name } }), {}));
        this.inputData && (serialized.inputData = this.inputData.reduce((obj, item) =>
            Object.assign(obj, { [item.id]: { id: item.id, unit: item.unit, value: item.value, _displayName: item.name } }), {}));

        return serialized;
    }

    static deserialize (object) {
        const energy = super.deserialize(...arguments);
        energy.directEmission = object.directEmission;
        energy.renewable = object.renewableBool;
        energy.limit = object.energyLimit;

        Object.entries({ ...object.statDependency, ...object.inputData }).forEach(([key, value]) => {
            energy.addCustomAttribute(key, value.value, value.unit, value._displayName)
        });

        return energy;
    }
}

export default EndEnergy;