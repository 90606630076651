export default [
    {
        path: '/',
        redirect: '/location',
        name: 'default',
        component: () => import('./views/Location.vue')
    },
    {
        path: '/location',
        name: 'location',
        component: () => import('./views/Location.vue')
    },
    {
        path: '/energyrequirement',
        name: 'energyrequirement',
        component: () => import('./views/EnergyRequirement.vue')
    },
    {
        path: '/variants',
        name: 'variants',
        component: () => import('./views/Scenarios.vue')
    },
    {
        path: '/evaluation',
        name: 'evaluation',
        component: () => import('./views/Evaluation.vue')
    },
    {
        path: '/components',
        name: 'components',
        component: () => import('./demos/Components.vue')
    }
]
