/**
 * Used for library builds of the application. This file serves the same prupose as the main.js file, but differs:
 * - The app is not mounted right away, because this is a responsibility of the app including the library
 * - The app does have additional option for providing a config to the instance
 * - A class is exported that can be instanced to access the vue instance and mount the app into a container
 */
import Vue from 'vue';
import App from './App.vue';
import Router from 'vue-router';
import routes from './routes';
import store from './store/store';
import i18n from './plugins/i18n';
import vuetify from './plugins/vuetify';
import fullscreen from 'vue-fullscreen';
import './styles/sass/forms.scss';
import './styles/sass/animations.scss';
import 'flag-icons/css/flag-icons.min.css';
import '@progress/kendo-theme-default/dist/all.css';
import validator from './scripts/Rules/validator';
import defaultBehaviour from './behaviours/defaultBehaviour'
import styles from './styles/sass/export.module.scss'

import StateImporter from './scripts/ImportExport/StateImporter'
import StateExporter from './scripts/ImportExport/StateExporter'


Vue.use(fullscreen);
Vue.use(Router);
Vue.config.productionTip = false;
Vue.prototype.$validator = validator;
Vue.prototype.$behaviourConfig = defaultBehaviour;
Vue.prototype.$scssVars = styles;
Vue.prototype.$isMac = navigator.userAgent.includes('Mac')

// Note: This is the default export. If using in a non-node env, you might need to instantiate BRUI.default to get this class.
//  In node you can just do something like "import BRUI from '<umd-file>'" to get the correct constructor.
export default class BRUI {
    /**
     * Constructor of the object that will be made available when including the library build of BRUI.
     * @param {object} config The object used to configure the BRUI app. Will also be appended to the BRUI Vue instance.
     * @param {object} config.behaviour A config object of behaviours that should be applied to the BRUI app. Behaviours influence how the BRUI UI behaves
     *  in the app that included BRUI. Mainly influences the existence or active state of components. If behaviours are in conflict, the last mentioned behaviour wins.
     *  For more information and a full set of available config options, see the defaultBehaviour object in the behaviours folder and read the BRUI documentation.
     * @returns The BRUI Vue instance created in the constructor.
     */
    constructor (config) {
        if (typeof config.behaviour === 'object' && config.behaviour !== null) {
            Vue.prototype.$behaviourConfig = { ...Vue.prototype.$behaviourConfig, ...config.behaviour };
        } // else part maybe possible for a further preset functionality or array support or something else

        const router = new Router({
            mode: Vue.prototype.$behaviourConfig.router.mode,
            routes
        });
        const Instance = new Vue({
            store,
            router,
            i18n,
            vuetify,
            fullscreen,
            data: { config: {} },
            render: h => h(App)
        });

        Vue.set(Instance, 'config', config);
        if (Vue.prototype.$behaviourConfig.router.mode === 'abstract') {
            router.replace('/');
        }
        return Instance;
    }
}

// These two classes are relevant for exporting/importing data from/to BRUI from an app that includes BRUI as a lib
// These classes can be accessed via BRUI.<classname> in a non-node env and via "import { <classname> } from '<umd-file>'" in node.
export { StateImporter, StateExporter };
