import ObjectHelper from '@/scripts/Helper/Object';
import NeededEnergy from '@/scripts/Objects/Energy/NeededEnergy';
import Building from '@/scripts/Objects/Building';

class EnergyPrognosis {
    energies = [];
    building = null;
    originalPrognosis = null;
    wasChanged = false;

    constructor (building, energies, storeOriginal = true) {
        this.building = building;
        this.energies = energies;
        if (storeOriginal) {
            this.originalPrognosis = new EnergyPrognosis(ObjectHelper.cloneWithPrototype(building), ObjectHelper.cloneWithPrototype(energies), false);
        }
    }

    resetToOriginal () {
        if (this.originalPrognosis) {
            this.building = ObjectHelper.cloneWithPrototype(this.originalPrognosis.building);
            this.energies = ObjectHelper.cloneWithPrototype(this.originalPrognosis.energies);
            this.wasChanged = false;
        }
    }

    serialize () {
        const serialized = {
            building: this.building.serialize(false),
            energies: this.energies.map((energy) => energy.serialize()),
        };

        if (this.originalPrognosis) {
            serialized.originalPrognosis = this.originalPrognosis.serialize();
        }
        return serialized;
    }

    static deserialize (object) {
        const energyPrognosis = new this(Building.deserialize(object.building), object.energies.map((energy) => NeededEnergy.deserialize(energy)), false);
        if (object.originalPrognosis) {
            energyPrognosis.originalPrognosis = EnergyPrognosis.deserialize(object.originalPrognosis);
        }
        return energyPrognosis;
    }

}

export default EnergyPrognosis;