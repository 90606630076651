import ObjectHelper from '@/scripts/Helper/Object';

/**
 * Class that reports attribute changes to the function onAttributeChanged (needs to be implemented within base class)
 * Doesnt able to observe deep attributes
 *
 * Usage:
 * Extend from this class
 *
 * or
 *
 * return an instance of this class within the constructor of your target class
 */
class AttributeObserverProxy {
    constructor (objectToObserve = this) {
        return new Proxy(objectToObserve, {
            set: (object, attributeName, value) => {
                const oldObject = ObjectHelper.cloneWithPrototype(object);
                const oldValue = object[attributeName];
                object[attributeName] = value;
                typeof object.onAttributeChanged === 'function' && object.onAttributeChanged(attributeName, value, oldValue, oldObject);
                return true;
            }
        });
    }
}

export default AttributeObserverProxy;