<i18n>
{
    "en": {
        "snakeButton": "Play Snake"
    },
    "de": {
        "snakeButton": "Snake spielen"
    }
}
</i18n>

<!--
    A component for showing either loading texts or an image roll inside a v-overlay component.
    A linear loading bar with a translation for "Loading" will always be visible above the loading bar, regardless if showing text or images.<template>
    Texts and images cannot be shown at the same time. Images will always win (if texts and images given).
-->
<template>
    <v-overlay v-if="shown" class="overlay floating-center" absolute>
        <p>{{ loadingText }}</p>
        <v-progress-linear
            class="loading-bar"
            indeterminate
            color="cyan"
        />
        <br>

        <div v-if="snakeEnabled">
            <v-btn v-if="snakeGame.status === 0" color="primary"
                style="margin-bottom:20px" @click="snakeStart()"
            >
                {{ $t("snakeButton") }}&nbsp;
                <v-icon>fa-angle-double-right</v-icon>
            </v-btn>
            <div v-else ref="snake" class="snake-container" />
        </div>
        <p v-if="additionalText && images.length === 0" class="loading-screen-text">
            {{ additionalText }}
        </p>
        <p v-show="showImages" class="image-container">
            <v-fade-transition :duration="{ enter: imageFadeDelay, leave: imageFadeDelay }">
                <v-img v-if="images.length" :key="imageSrc" transition="fade-transition" width="70vmin" :src="imageSrc" />
            </v-fade-transition>
        </p>
    </v-overlay>
</template>

<script>
import SnakeGame from 'dbi-snake-game';
export default {
    props: {
        loadingText: {
            type: String,
            default () {
                return `${this.$t('common.terms.loading')}...`
            }
        },
        additionalText: {
            type: String,
            default () {
                return ''
            }
        },
        images: {
            type: Array,
            default () {
                return []
            }
        },
        /**
         * The time that goes by on the first load before the first image is shown.
         */
        initialImageDelay: {
            type: Number,
            default: 800
        },
        /**
         * The complete lifetime of the image, without the face times. So basically the time the image is completeley visible.
         */
        imageLifeTime: {
            type: Number,
            default: 7500
        },
        /**
         * The length of the fade transition between the images.
         */
        imageFadeDelay: {
            type: Number,
            default: 1200
        },
        snakeEnabled: {
            type: Boolean,
            default: true
        },
        showLoadingScreen: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            activeImage: 0,
            showImages: false,
            imageSrc: '',
            imageChangeIntervall: null,
            snakeGame: {status: 0},
        }
    },
    computed: {
        shown () {
            return this.showLoadingScreen || (this.snakeGame.status !== 0 && this.snakeEnabled)
        }
    },
    watch: {
        shown () {
            if (!this.shown) {
                clearInterval(this.imageChangeIntervall)
                if (this.snakeGame.status !== 0) {
                    this.snakeGame.destroy()
                }
            } else {
                this.showImageRoll()
            }
        },
        snakeEnabled () {
            if (!this.snakeEnabled && this.snakeGame.status !== 0) {
                this.snakeGame.destroy()
            }
        }
    },
    mounted () {
    },
    methods: {
        /**
         * Sets an intervall that changes the images from this.images[0] to this.images[n] every this.imageLifeTime ms.
         */
        showImageRoll () {
            if (this.images.length) {
                new Promise(() => setTimeout(() => this.showImages = true, this.initialImageDelay));
                [this.imageSrc] = this.images
                if (this.images.length > 1) {
                    const that = this
                    this.imageChangeIntervall = setInterval(() => {
                        // simulate a transition between images without the images overlapping or stacking over each other
                        that.imageSrc = ''
                        setTimeout(() => {
                            that.activeImage = (that.activeImage + 1) % (that.images.length || 1)
                            that.imageSrc = that.images[that.activeImage]
                        }, that.imageFadeDelay)
                    }, this.imageLifeTime + this.imageFadeDelay)
                }
            }
        },
        /**
         * creates a new SnakeGame and starts the game loop
         */
        async snakeStart () {
            this.snakeGame.status = 1
            const config = {
                'initLength': 5,
            };
            await this.$nextTick()
            this.snakeGame = new SnakeGame(this.$refs.snake, config)
            this.snakeGame.start()
        }
    }
}
</script>

<style lang="scss" scoped>
.floating-center {
  position: sticky;
  height: 100vh;
}

.loading-screen-text {
    margin-top: 10px;
}

.loading-bar {
    width: 66vw;
}

.image-container {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
}

.snake-container {
    width: 40vmin;
    height: 40vmin;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}
</style>