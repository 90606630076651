import AbstractAPIConnector from '../AbstractAPIConnector'
import i18n from '@/plugins/i18n';
import APIError from '@/scripts/Connectors/APIError';
import ArrayHelper from '@/scripts/Helper/Array';

class BERTAAPIConnector extends AbstractAPIConnector {
    APIUrl = process.env.VUE_APP_BERTA_API_URL

    async requestAllBuildingtypes (language = 'DE', buildingScore = 1, modelRanking = 1) {
        const requestURL = `${this.APIUrl}/api/buildingtypes?modelRanking=${modelRanking}&buildingScore=${buildingScore}&language=${language}`
        const jsonData = await this._fetchJSONFromURL(requestURL)
        for (const key in jsonData) {
            if (!jsonData[key].hasOwnProperty('tags')) {
                continue
            }
            jsonData[key].tags = ArrayHelper.parseFromString(jsonData[key].tags)
        }
        return Object.values(jsonData)
    }

    async requestBuildingClassificationOptions (language = 'DE', buildingScore = 1, modelRanking = 1) {
        const requestURL = `${this.APIUrl}/api/buildingtypes/options?modelRanking=${modelRanking}&buildingScore=${buildingScore}&language=${language}`
        const jsonData = await this._fetchJSONFromURL(requestURL)
        return jsonData
    }

    async requestAllEnergytypes (language = 'DE') {
        const requestURL = `${this.APIUrl}/api/energytypes?language=${language}`
        const jsonData = await this._fetchJSONFromURL(requestURL)
        for (const key in jsonData) {
            if (!jsonData[key].hasOwnProperty('tags')) {
                continue
            }
            jsonData[key].tags = ArrayHelper.parseFromString(jsonData[key].tags)
        }
        return Object.values(jsonData)
    }

    async requestAvailableEnergyModelsForBuilding (building, language = 'DE', buildingScore = 1) {
        const requestURL = `${this.APIUrl}/api/building?language=${language}&buildingScore=${buildingScore}`

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(building)
        }

        const jsonData = await this._fetchJSONFromURL(requestURL, requestOptions);
        return jsonData
    }

    async getLoadProfile (building, modelID, climateData, timestamps, language = 'DE') {
        const requestURL = `${this.APIUrl}/api/loadProfile?language=${language}&modelID=${modelID}`
        const inputData = this._buildEnergiesRequestBody(timestamps, building, climateData)

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        }

        const data = await this._fetchJSONFromURL(requestURL, requestOptions);
        return data
    }


    /**
     * A Function to fetch a JSON from a URL, this is an overwrite of the
     * AbstractAPIConnector function to handle BERTA api errors using the error handling method.
     *
     * @param {string} url                   The URL to fetch the JSON from
     * @param {Object} requestOptions        The request options to use for the fetch
     * @returns {Promise<*|void|T>}          The fetched JSON
     * @private
     */
    async _fetchJSONFromURL (url, requestOptions) {
        const error_handler = (response) => {
            if (response.status < 200 || response.status > 299) {
                BERTAAPIConnector.apiErrorHandler(response)
            }
            return response
        }

        return super._fetchJSONFromURL(url, requestOptions).then(error_handler)
    }


    /**
     * Evaluates RUDI error types and throws error messages accordingly
     * @param {Object} apiError error to handle (response.json)
     */
    static apiErrorHandler (apiError) {
        const errorText = i18n.t('common.notifications.unknownError')
        const errorCode = `${apiError.status}`

        throw new APIError(JSON.stringify({errorCode}, null, 2), null, null, errorText)
    }

    // eslint-disable-next-line class-methods-use-this
    _buildEnergiesRequestBody (timestamps, buildings, climateData) {
        const inputBody = {
            'timeStamps': timestamps,
            'buildings': [
                buildings
            ],
            'inputParameters': [
                {
                    'name': 'month',
                    'unit': '',
                    'values': timestamps.map(date => (new Date(date)).getMonth() + 1),
                },
                {
                    'name': 'weekday',
                    'unit': '',
                    'values': timestamps.map(date => ((new Date(date)).getDay() + 7 - 1) % 7),
                },
                {
                    'name': 'hour',
                    'unit': '',
                    'values': timestamps.map(date => (new Date(date)).getHours()),
                }
            ]
        }
        if (climateData.data.t.values.length === timestamps.length) {
            inputBody.inputParameters.push({
                'name': 'airTemperature',
                'unit': '°C',
                'values': climateData.data.t.values
            })
        }
        if (climateData.data.RF.values.length === timestamps.length) {
            inputBody.inputParameters.push({
                'name': 'relativeHumidity',
                'unit': '%',
                'values': climateData.data.RF.values
            })
        }
        if (climateData.data.B.values.length === timestamps.length) {
            inputBody.inputParameters.push({
                'name': 'radiation',
                'unit': 'W/qm',
                'values': climateData.data.B.values
            })
        }

        return inputBody
    }
}
export default BERTAAPIConnector
