import Vue from 'vue'
import Validators from '../scripts/Rules/validator'
import ArrayHelper from '@/scripts/Helper/Array';

/**
 * KEEP IN MIND:
 * Some mutations needs direct references from the state to work.
 * This will be change in future when we are working on the EPIC: BRUI-415
 */
export default {
    // General
    languageChange (state, lang) {
        state.language = lang
    },
    currentPageIndex (state, n) {
        state.currentPageIndex = n
    },
    tableForms (state, formData) {
        state.tableForms[formData.storeKey] = formData.data
    },
    // Climate data
    climateData (state, { raw, data, labels }) {
        const climateData = {}
        climateData.raw = raw || state.climateData.raw || {}
        climateData.data = data || state.climateData.data || {}
        climateData.labels = labels || state.climateData.labels || []

        state.climateData = climateData
    },
    customClimateData (state, data) {
        Vue.set(state.climateData, 'customData', data)
    },
    // Object, Buildings & Building energies
    objectDescription (state, objectDescription) {
        if (objectDescription.area && !Validators.isNotNegative(objectDescription.area)) {
            delete objectDescription.area
        }
        state.objectDescription = { ...state.objectDescription, ...objectDescription }
    },
    objectSums (state, sums) {
        state.objectDescription = { ...state.objectDescription, ...sums }
    },
    buildingTypes (state, data) {
        state.buildingTypes = data
    },
    energyTypes (state, data) {
        state.energyTypes = data
    },
    objectEnergies (state, data) {
        state.objectEnergies = data
    },
    buildingGroups (state, buildingGroups) {
        state.buildingGroups = buildingGroups
    },

    addBuildingGroup (state, buildingGroup) {
        state.buildingGroups.push(buildingGroup);
    },

    removeBuildingGroup (state, buildingGroup) {
        ArrayHelper.spliceItem(state.buildingGroups, buildingGroup, 1, null, (group) => group.id === buildingGroup.id);
    },

    addBuildingToGroup (state, { building, groupIndex }) {
        state.buildingGroups[groupIndex].data.push(building);
    },

    removeBuildingFromGroup (state, { building, groupIndex }) {
        ArrayHelper.spliceItem(state.buildingGroups[groupIndex].data, building);
    },

    updateBuilding (state, { building, data }) {
        for (const [key, value] of Object.entries(data)) {
            if (building.hasOwnProperty(key)) {
                building[key] = value;
            }
        }
    },

    setBuildingPrognosis (state, { building, prognosis}) {
        building.prognosis = prognosis;
    },

    updateBuildingLocation (state, { building, location }) {
        building.setLocation(location.city, location.latlng);
    },

    /**
     * reset the prognosis to the original prognosis
     * @param {object} state vuex state object
     * @param {EnergyPrognosis} prognosis prognosis object to reset (must be a reference from the vuex store)
     */
    resetPrognosis (state, { prognosis }) {
        prognosis.resetToOriginal();
    },


    /**
     * Resets the refBuildings and the prognosis of all buildings
     * @param {object} state vuex state object
     */
    clearAllBuildingRefBuildings (state) {
        state.buildingGroups = state.buildingGroups.map((group) => ({
            ...group,
            data: group.data.map((building) => {
                building.referenceBuildings = [];
                building.prognosis = null;
                return building;
            })
        }))
    },

    // Scenarios
    scenarioChange (state, { index, data }) {
        if (typeof state.scenarios[index] !== 'undefined') {
            if (data.finances.interestOnCapitalPercentage && !Validators.isBetween(data.finances.interestOnCapitalPercentage, 0, 1)) {
                data.finances.interestOnCapitalPercentage = state.scenarios[index].finances.interestOnCapitalPercentage
            }
            if (data.finances.periodOfTime.value && !Validators.isBetween(data.finances.periodOfTime.value, 0, 50)) {
                data.finances.periodOfTime.value = state.scenarios[index].finances.periodOfTime.value
            }
            Vue.set(state.scenarios, index, data)
        }
    },
    scenarioAdd (state, data) {
        state.scenarios.push(data)
    },
    scenarioDelete (state, index) {
        state.scenarios.splice(index, 1)
    },
    scenarioOptions (state, { supplier, storages, endEnergies, finances, optimization, globalStatDependency }) {
        supplier && Vue.set(state.scenarioOptions, 'supplier', supplier)
        storages && Vue.set(state.scenarioOptions, 'storages', storages)
        endEnergies && Vue.set(state.scenarioOptions, 'endEnergies', endEnergies)
        finances && Vue.set(state.scenarioOptions, 'finances', finances)
        optimization && Vue.set(state.scenarioOptions, 'optimization', optimization)
        globalStatDependency && Vue.set(state.scenarioOptions, 'globalStatDependency', globalStatDependency)
    },
    scenarioEntities (state, { index, suppliers, storages, endEnergies, neededEnergies, usedSuppliers, usedStorages, usedEndEnergies, usedNeededEnergies }) {
        suppliers && state.scenarios[index] && Vue.set(state.scenarios[index], 'suppliers', suppliers)
        storages && state.scenarios[index] && Vue.set(state.scenarios[index], 'storages', storages)
        endEnergies && state.scenarios[index] && Vue.set(state.scenarios[index], 'endEnergies', endEnergies)
        neededEnergies && state.scenarios[index] && Vue.set(state.scenarios[index], 'neededEnergies', neededEnergies)
        usedSuppliers && state.scenarios[index] && Vue.set(state.scenarios[index], 'usedSuppliers', usedSuppliers)
        usedStorages && state.scenarios[index] && Vue.set(state.scenarios[index], 'usedStorages', usedStorages)
        usedEndEnergies && state.scenarios[index] && Vue.set(state.scenarios[index], 'usedEndEnergies', usedEndEnergies)
        usedNeededEnergies && state.scenarios[index] && Vue.set(state.scenarios[index], 'usedNeededEnergies', usedNeededEnergies)

        // if an entity was changed, the dependency has to be recalculated
        state.scenarios[index].buildUpDependencyGraph()
    },
    scenarioResults (state, { result, index }) {
        state.scenarios[index].result = result
        Vue.set(state.scenarios, index, state.scenarios[index])
    },
    invalidateScenarioResult (state, index) {
        delete state.scenarios[index].result
    },

    addGlobalCustomAttribute ({ globalCustomAttributes }, attribute) {
        globalCustomAttributes.push(attribute);
    },

    updateGlobalCustomAttribute ({ globalCustomAttributes }, { attribute, changes }) {
        const attributeToUpdate = globalCustomAttributes.find((attr) => attr.id === attribute.id);
        if (attributeToUpdate) {
            for (const [key, value] of Object.entries(changes)) {
                attributeToUpdate[key] = value;
            }
        }
    },

    deleteGlobalCustomAttribute ({ globalCustomAttributes }, attribute) {
        const attributeIndex = globalCustomAttributes.findIndex((attr) => attr.id === attribute.id);
        if (attributeIndex !== -1) {
            globalCustomAttributes.splice(attributeIndex, 1)
        }
    },

    // RUDI calculation vars
    withPreview (state, value) {
        state.scenarioCalculationOptions.withPreview = value
    },

    withPlainResults (state, value) {
        state.scenarioCalculationOptions.withPlainResults = value
    }
}
