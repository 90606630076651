export const GraphColoring = {
    WHITE: 'WHITE',
    GRAY: 'GRAY',
    BLACK: 'BLACK'
}

/**
 * Return the min Color of the given colors
 *
 * @param {String} colorA  The first color
 * @param {String} colorB  The second color
 * @return {String}        The min color
 */
export function getMinColor (colorA, colorB) {
    const idxA = Object.values(GraphColoring).indexOf(colorA)
    const idxB = Object.values(GraphColoring).indexOf(colorB)
    return Object.values(GraphColoring)[Math.min(idxA, idxB)]
}

/**
 * Return the max Color of the given colors
 *
 * @param {String} colorA  The first color
 * @param {String} colorB  The second color
 * @return {String}        The max color
 */
export function getMaxColor (colorA, colorB) {
    const idxA = Object.values(GraphColoring).indexOf(colorA)
    const idxB = Object.values(GraphColoring).indexOf(colorB)
    return Object.values(GraphColoring)[Math.max(idxA, idxB)]
}
