class ObjectHelper {
    static clone (original, shallow = false) {
        let ret = original;
        // if object has a clone function otherwise copy manually
        if (ObjectHelper.hasCloneMethod(original)) {
            ret = original.clone()
        } else if (typeof original === 'object') {
            ret = Object.prototype.toString.call(original) === '[object Array]' ? [] : {};
            for (const prop in original) {
                if (Object.prototype.hasOwnProperty.call(original, prop)) {
                    const value = original[prop];
                    ret[prop] = (value && typeof value === 'object' && !shallow) ? this.clone(value) : value;
                }
            }
        }
        return ret;
    }

    /**
     * Uses clone and Object assign to the objects prototype, to clone an object with its prototype.
     * @param {Object|Array} original The object to clone
     * @param {Boolen} shallow If only a shallow copy of the object should be made
     * @returns {Object} The cloned version of the object. The prototype will not be cloned
     */
    static cloneWithPrototype (original) {
        if (original === null) {
            return null;
        }
        let ret = original;
        // if object has a clone function otherwise copy manually
        if (ObjectHelper.hasCloneMethod(original)) {
            ret = original.clone()
        } else if (typeof original === 'object') {
            ret = Object.prototype.toString.call(original) === '[object Array]' ? [] : Object.assign(Object.create(Object.getPrototypeOf(original)), {});
            for (const prop in original) {
                // guard clause to skip prototype
                if (!Object.prototype.hasOwnProperty.call(original, prop)) {
                    continue;
                }

                const value = original[prop];
                const valueIsArray = Object.prototype.toString.call(value) === '[object Array]'
                if (valueIsArray) {
                    ret[prop] = value.map(v => ObjectHelper.cloneWithPrototype(v))
                } else if (typeof value === 'object') {
                    ret[prop] = ObjectHelper.cloneWithPrototype(value)
                } else {
                    ret[prop] = value
                }
            }
        }
        return ret;
    }

    /**
     * Prueft, ob die Werte gleich sind.
     * Die Methode kann nicht mit zirkulaeren Abhaengigkeiten und ES6-Features (Map/Set) umgehen.
     *
     * @author Serhii Zarva
     * @param {*} a erster Wert fuer den Vergleich
     * @param {*} b zweiter Wert fuer den Vergleich
     * @returns {boolean} True wenn die Werte gleich sind
    */
    static deepEqual (a, b) {
        let length, i, keys, key;
        if (a === b) {
            return true;
        }
        if (a && b && typeof a === 'object' && typeof b === 'object') {
            if (a.constructor !== b.constructor) {
                return false;
            }
            if (Array.isArray(a)) {
                // eslint-disable-next-line prefer-destructuring
                length = a.length;
                if (length !== b.length) {
                    return false;
                }
                for (i = length; i-- !== 0;) {
                    if (!this.deepEqual(a[i], b[i])) {
                        return false;
                    }
                }
                return true;
            }
            if (a.constructor === RegExp) {
                return a.source === b.source
              && a.global === b.global
              && a.ignoreCase === b.ignoreCase
              && a.multiline === b.multiline
              && a.unicode === b.unicode
              && a.lastIndex === b.lastIndex
              && a.sticky === b.sticky
                ;
            }
            if (a.valueOf !== Object.prototype.valueOf) {
                return a.valueOf() === b.valueOf();
            }
            if (a.toString !== Object.prototype.toString) {
                return a.toString() === b.toString();
            }
            keys = Object.keys(a);
            // eslint-disable-next-line prefer-destructuring
            length = keys.length;
            if (length !== Object.keys(b).length) {
                return false;
            }
            for (i = length; i-- !== 0;) {
                if (!Object.prototype.hasOwnProperty.call(b, keys[i])) {
                    return false;
                }
            }
            for (i = length; i-- !== 0;) {
                key = keys[i];
                if (!this.deepEqual(a[key], b[key])) {
                    return false;
                }
            }
            return true;
        }
        // true wenn beide NaN sind, sonst false
        return a !== a && b !== b;
    }

    static objectArrayToObject (array, keyProperty = 'id') {
        const result = {}
        array.forEach(entry => {
            result[entry[keyProperty]] = entry
        })

        return result
    }

    static objectToObjectArray (object, keyProperty = 'id') {
        const objectArray = []
        for (const [key, values] of Object.entries(object)) {
            objectArray.push({ ...values, [keyProperty]: key })
        }
        return objectArray
    }

    static hasCloneMethod (object) {
        return typeof object === 'object' && 'clone' in object && typeof object.clone === 'function'
    }
}

export default ObjectHelper