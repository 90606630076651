export default {
    'de': {
        decimal: {
            style: 'decimal', useGrouping: true, maximumFractionDigits: 2
        },
        // no options ovverrides for $n in vue-i18n v8. Only is available in v9. Delete this if we update to v9 and use $n overload.
        integer: {
            style: 'decimal', useGrouping: true, maximumFractionDigits: 0
        },
        currency: {
            style: 'currency', currency: 'EUR', currencyDisplay: 'symbol', useGrouping: true, maximumFractionDigits: 2
        },
        percent: {
            style: 'percent', useGrouping: true, maximumFractionDigits: 2
        },
        unit: {
            style: 'unit', useGrouping: true, maximumFractionDigits: 2
        }
    },
}