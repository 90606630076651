class UnitConverter {

    // Maps energy units to their work units
    static workUnitMappings = {
        'W': 'Wh',
        'kW': 'kWh',
        'MW': 'MWh',
        'Nm³/h': 'Nm³',
        'Nm³/min': 'Nm³',
        'l/h': 'l',
        'm³/h': 'm³'
    };

    // Maps time depenedant units to their target units
    static timeCorrectionFunctions = {
        'Nm³/s': {
            defaultUnit: 'Nm³/min',
            correctionFn: (v) => v * 60
        }
    };

    /**
     * Checks whether there is a default time corrected unit saved in this class that could be converted to.
     * @param {String} unit The unit to look up.
     * @returns {Boolean} If a default time corrected unit exists.
     */
    static hasTimeCorrectionDefaultUnit (unit) {
        return this.timeCorrectionFunctions.hasOwnProperty(unit)
    }

    /**
     * Corrects values given with a specific time dependant unit to values to another time dependant target unit with a given correction function. If no target unit
     * or correction function is given, the mappings in this.timeCorrectionFunctions are used (if an entry for the given unit exists).
     * @param {Number[]} values An array of values to correct.
     * @param {String} unit The unit the values in the values parameter are given in.
     * @param {Function} [correctionFn=null] Optional. The function that should be used to correct the units
     * @param {String} [correctedUnit=null] Optional. The result unit after the value was processed by the correction function.
     * @returns {Object} An object with attributes: values (The corrected values), unit (The target unit).
     */
    static correctTimeUnitValuesToDefault (values, unit, correctionFn = null, correctedUnit = null) {
        let correctionFnToUse = correctionFn;
        let correctedUnitToUse = correctedUnit;

        if (!correctionFnToUse) {
            correctionFnToUse = this.timeCorrectionFunctions[unit] ? this.timeCorrectionFunctions[unit].correctionFn : null;

            if (!correctionFnToUse || typeof correctionFnToUse !== 'function') {
                throw new Error(`There was no default correction function for unit ${unit}. Please pass your own correction function.`);
            }
        }

        if (!correctedUnitToUse) {
            correctedUnitToUse = this.timeCorrectionFunctions[unit] ? this.timeCorrectionFunctions[unit].defaultUnit : null;
        }

        return {
            values: values.map(v => correctionFnToUse(v)),
            unit: correctedUnitToUse
        };
    }

    /**
     * Will convert any value in a given root unit within a metric unit system (factors of 1000) to a number with the given factor that has the
     * correct target unit. This function is used by functions like convertPower, convertWeight, convertWork, ...
     * @example You want to show the value 1000W divided by 1000 (because its too long else). You dont know the target unit. You can now call
     *  convertPower(1000, 'W', 1000) to convert 1000W to 1kW.
     * @param {Array} units An array of strings describing units, beginning from SI default unit, each further entry with a factor of 1000.
     * @param {Number} value The value to convert
     * @param {String} rootUnit The unit the given value is in
     * @param {Number} factor The factor by which the value should be divided by
     * @param {Number} [numberOfDecimals=2] The maximum number of decimals display, when neccessary.
     * @param {Boolean} [spaced=false] If a white space character should be displayed between the value and the unit.
     * @param {Boolean} [onlyUnit=false] If only the unit of the conversion should be returned. value, numberOfDecimals can be set to 0, spaced to false.
     * @param {Boolean} [onlyValue=false] If only the value of the conversion should be returned. This will loose over onlyUnit being true.
     * @returns The converted value with the correct target unit.
     * @todo: This could be more variable to allow infinite units with scaling factor 1000, not only 4 units.
     */
    static convertMetric (units, value, rootUnit, factor, numberOfDecimals = 2, spaced = false, onlyUnit = false, onlyValue = false) {
        const possibleUnits = units;

        // Counter floating point inaccuracy of parameter
        // eslint-disable-next-line no-param-reassign
        value = this._toFixed(value);
        // eslint-disable-next-line no-param-reassign
        factor = this._toFixed(factor);

        // Checks if input params were valid. Errors when not.
        let thirdRootOfFactor = factor >= 1 ? Math.ceil(Math.pow(factor, 1 / 3)) : Math.pow(factor, 1 / 3);
        // Counter floating point inaccuracy from calculation above
        if (thirdRootOfFactor < 1 && thirdRootOfFactor.toString().split('.')[1].length > 3) {
            thirdRootOfFactor = thirdRootOfFactor.toFixed(3);
        }
        const charArray = `${thirdRootOfFactor}`.replace('.', '').split('');
        const firstChar = charArray.shift();
        if (
            // starts with 1 and all following are 0 (e.g. 1000, 100000)
            !(firstChar === '1' && charArray.every(n => n === '0'))
            // starts with 0, has one 1 in it, everything else is a 0 (e.g. 0.0001, 0.00010000)
            && !(firstChar === '0'
                    && charArray.join('').replace(/[^0]/g, '').length === charArray.length - 1
                    && charArray.join('').replace(/[^1]/g, '').length === 1
            )
        ) {
            throw new Error('The factor you gave is not valid. Third root of factor must be divisible by 10 or 100 or 1000 or ...');
        }
        if (!possibleUnits.includes(rootUnit)) {
            throw new Error(`The root unit '${rootUnit}' is not valid. Can only convert between ${possibleUnits.join(', ')}`);
        }
        if (rootUnit === possibleUnits[0] && factor < 1) {
            throw new Error(`Cannot convert downwards from unit '${possibleUnits[0]}'`);
        }

        // First get value back to Watts, get a factor that will calculate Watts to the target unit
        const indexOfRootUnit = possibleUnits.indexOf(rootUnit);
        const factorFromWatts = factor * Math.pow(1000, indexOfRootUnit);

        // Find target unit
        // todo: can be made more variable.
        /* eslint-disable prefer-destructuring */
        let unitAfterFactor = possibleUnits[0];
        if (factorFromWatts <= 1) unitAfterFactor = possibleUnits[0];
        else if (factorFromWatts > 1 && factorFromWatts <= 1000) unitAfterFactor = possibleUnits[1];
        else if (factorFromWatts > 1000 && factorFromWatts <= 1000 * 1000) unitAfterFactor = possibleUnits[2];
        else if (factorFromWatts > 1000 * 1000 && factorFromWatts <= 1000 * 1000 * 1000) unitAfterFactor = possibleUnits[3];
        /* eslint-enable prefer-destructuring */

        if (onlyUnit) {
            return unitAfterFactor;
        }

        const valueInWatts = value * Math.pow(1000, indexOfRootUnit);
        const result = valueInWatts / factorFromWatts;
        // Cut unneccessary decimals
        let shownDecimals = 0
        if (`${result}`.includes('.')) {
            const resultNumberOfDecimals = result.toString().split('.')[1].length;
            shownDecimals = resultNumberOfDecimals > numberOfDecimals ? numberOfDecimals : resultNumberOfDecimals;
        }

        const resultValue = (valueInWatts / factorFromWatts).toFixed(shownDecimals)

        if (onlyValue) {
            return parseFloat(resultValue)
        }

        return `${resultValue}${spaced ? ' ' : ''}${unitAfterFactor}`;
    }

    static convertPower (value, rootUnit, factor, numberOfDecimals = 2, spaced = false, onlyUnit = false, onlyValue = false) {
        return this.convertMetric(['W', 'kW', 'MW', 'GW'], value, rootUnit, factor, numberOfDecimals, spaced, onlyUnit, onlyValue);
    }

    static convertPowerUnit (rootUnit, factor) {
        return this.convertPower(0, rootUnit, factor, 0, false, true);
    }

    static convertWork (value, rootUnit, factor, numberOfDecimals = 2, spaced = false, onlyUnit = false, onlyValue = false) {
        const powerUnit = rootUnit.replace('h', '');
        const conversionResult = this.convertPower(value, powerUnit, factor, numberOfDecimals, spaced, onlyUnit, onlyValue)

        if (onlyValue) {
            return conversionResult
        }

        return `${conversionResult}h`;
    }

    static convertWeight (value, rootUnit, factor, numberOfDecimals = 2, spaced = false, onlyUnit = false, onlyValue = false) {
        return this.convertMetric(['kg', 't', 'Mt', 'Gt'], value, rootUnit, factor, numberOfDecimals, spaced, onlyUnit, onlyValue);
    }

    static convertWeightUnit (rootUnit, factor) {
        return this.convertWeight(0, rootUnit, factor, 0, false, true);
    }

    static convertDistance (value, rootUnit, factor, numberOfDecimals = 2, spaced = false, onlyUnit = false, onlyValue = false) {
        return this.convertMetric(['mm', 'cm', 'm', 'km'], value, rootUnit, factor, numberOfDecimals, spaced, onlyUnit, onlyValue);
    }

    /**
     * COnverts a given unit to its corresponding unit for work values. E.q. W ist converted to Wh.
     * @param {String} unit The unit to convert.
     * @returns {String} The corresponding work value saved in this.workUnitMappings.
     */
    static convertUnitToWorkUnit (unit) {
        return this.workUnitMappings[unit]
    }

    static _toFixed (x) {
        let valueToConvert = x
        if (Math.abs(valueToConvert) < 1.0) {
            const e = parseInt(valueToConvert.toString().split('e-')[1]);
            if (e) {
                valueToConvert *= Math.pow(10,e - 1);
                valueToConvert = `0.${ (new Array(e)).join('0') }${valueToConvert.toString().substring(2)}`;
            }
        } else {
            const e = parseInt(valueToConvert.toString().split('+')[1]);
            if (e > 20) {
                e -= 20;
                valueToConvert /= Math.pow(10,e);
                valueToConvert += (new Array(e + 1)).join('0');
            }
        }
        return valueToConvert;
    }
}

export default UnitConverter