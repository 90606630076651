import AbstractParser from './AbstractParser'

/**
 * Parses specifically formatted data from the climate APIs TRY-data endpoint to a chart (and table) friendly format. This requires the keys of
 * the data to be human readable, a values Array, unit and sumUnit string, as well as the originalName of the dataset will be properties of the
 * data keys object.
 */
class TryDataParser extends AbstractParser {
    data = []

    constructor () {
        super(...arguments)
        this.labelField = 'DATE'
    }

    /**
     * Retruns the parsed data in a non-chartjs, but cleaner format to work with.
     * @returns an object which has the try-data data key as key and an object as value with properties: displayName, unit and values.
     *  Each entry in values is the value for the hour in the year for the index of the value
     *  (index: 0 is first hour of the year, index: 780 is the 781st hour of the year)
     */
    cleanParse () {
        const [{ fieldData }] = this._data._embedded.test_reference_year
        const [{ fieldDescription }] = this._data._embedded.test_reference_year

        const data = {}

        fieldData.forEach(row => {
            for (const key in row) {
                if (!this.dataFields.includes(key)) {
                    continue
                }
                if (!data.hasOwnProperty(key)) {
                    data[key] = {
                        id: key,
                        displayName: fieldDescription[key].name,
                        unit: fieldDescription[key].unit,
                        sumUnit: fieldDescription[key].sumUnit || fieldDescription[key].unit,
                        values: []
                    }
                }
                data[key].values.push(parseFloat(row[key]))
            }
        })

        return data
    }

    /**
     * Parses climate data into the chartjs format.
     * @returns The parsed climate data in a chartjs ready format
     */
    parse () {
        if (!this._data) {
            return null
        }
        const { fieldData } = { ...this._data._embedded.test_reference_year[0] }
        const { fieldDescription } = { ...this._data._embedded.test_reference_year[0] }

        const modifiedFieldData = []
        fieldData.forEach((row) => {
            const modifiedRow = {}
            // toISOString returns string with Z string for time zone. TRY data returns data independent from a specific day/year, so we can also
            // get rid of the time zone, since its not important here.
            modifiedRow[this.labelField] = new Date(`${row.MM}/${row.DD}/${new Date().getFullYear()} ${row.HH}:00`).toISOString().replace('Z', '')

            // adjust the data labels to fit the field descriptions. More or less replaces the api labels with the labels that should be shown to the user.
            // this creates an object with weird keys, but makes using the default parser way easier.
            for (const [fieldName, value] of Object.entries(row)) {
                if (fieldDescription.hasOwnProperty(fieldName)) {
                    modifiedRow[fieldDescription[fieldName].name] = { value, originalName: fieldName }
                }
            }
            modifiedFieldData.push(modifiedRow)
        })
        this.data = modifiedFieldData

        // datafields must be changed to fit the field descriptions set above. The default parser
        // will use these fields to match with the object properties changed above.
        this.dataFields.forEach((field, index) => {
            if (fieldDescription.hasOwnProperty(field)) {
                this.dataFields[index] = fieldDescription[field].name
            }
        })
        return super.parse()
    }

    /**
     * Extends the returned data from parent class by a unit field, containing the unit specified for this dataset
     * in the fieldDescription of the API response.
     * @returns The same object as returned by the parent class, but with an addtional unit field.
     */
    extractData () {
        const data = super.extractData(this.data)
        const { fieldDescription } = { ...this._data._embedded.test_reference_year[0] }
        const dataWithUnits = {}
        for (const key in data) {
            const [{ unit }] = Object.values(fieldDescription).filter(el => el.name === key)
            const [{ sumUnit }] = Object.values(fieldDescription).filter(el => el.name === key)
            const values = data[key].values.map(el => el.value)
            dataWithUnits[key] = {
                values,
                unit,
                sumUnit,
                // The name the dataset was named in the original dataset, before chart mappings to readable names were applied.
                originalName: data[key].values[0].originalName
            }
        }
        return dataWithUnits
    }

    extractLabels () {
        return this.data.map(point => point[this.labelField])
    }

    extractMetaData () {
        const [tryData] = this._data._embedded.test_reference_year
        return (
            ({ regionName, regionLat, regionLng, }) => ({ name: regionName, lat: regionLat, lng: regionLng }))
        (tryData);
    }

    toObject (data = null, labels = null) {
        return super.toObject(data, labels)
    }

}

export default TryDataParser