import AbstractAPIConnector from '../AbstractAPIConnector'

class DWDAPIConnector extends AbstractAPIConnector {
    APIUrl = process.env.VUE_APP_DWD_API_URL

    async requestWeatherDataByLatLng (lat, lng) {
        const requestURL = `${this.APIUrl}/station?nearLatLng=${lat},${lng}`
        const jsonData = await this._fetchJSONFromURL(requestURL)
        return jsonData
    }

    async requestTRYDataByLatLng (lat, lng) {
        const requestURL = `${this.APIUrl}/try?latlng=${lat},${lng}`
        const jsonData = await this._fetchJSONFromURL(requestURL)
        return jsonData
    }
}

export default DWDAPIConnector