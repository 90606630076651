import {GraphColoring, getMinColor, getMaxColor} from '@/scripts/Algorithms/DependencyValidator/Color';
import Node from '@/scripts/Algorithms/DependencyValidator/Node';

class Graph {
    constructor () {
        this.nodes = []
        this.somethingWasChanged = true
    }

    /**
     * Generates a reveresed Graph. Each sink will be a source and each source will be a sink.
     * The edges will be reversed as well.
     * @returns {Graph} The reversed Graph.
     */
    getReversedGraph () {
        const reversedGraph = new Graph()
        // reverse the node, to be a sink when it was a source and vice versa
        // reverse the inputs and outputs
        this.nodes.forEach(node => {
            const isSink = node.isSource
            const isSource = node.isSink
            const inputRelationKind = node.outputRelationKind
            const outputRelationKind = node.inputRelationKind
            const inputs = node.outputs
            const outputs = node.inputs
            const newNode = new Node(node.name, inputs, outputs, isSource, isSink, inputRelationKind, outputRelationKind)
            reversedGraph.addNode(newNode)
        })
        // build up the edges
        reversedGraph.buildEdges()
        return reversedGraph
    }

    /**
     * Adds a node to the graph.
     *
     * @param {Node} node   The node to add to the graph.
     * @returns {boolean}   True if the node was added, false if the node was already in the graph.
     */
    addNode (node) {
        // check if node is already in the graph
        if (this.nodes.includes(node)) {
            return false
        }
        this.nodes.push(node)
        return true
    }

    /**
     * Builds up all the Edges by checking the input/output matching for each node pair.
     */
    buildEdges () {
        this.nodes.forEach(node => {
            this.nodes.forEach(targetNode => {
                node.connectTargetNodeOnMatching(targetNode)
            })
        })
    }

    /**
     * Returns all the sources of the graph.
     *
     * @returns {Node[]}    The sources of the graph.
     */
    getSources () {
        return this.nodes.filter(node => node.isSource)
    }

    /**
     * Returns all the sinks of the graph.
     *
     * @returns {Node[]}    The sinks of the graph.
     */
    getSinks () {
        return this.nodes.filter(node => node.isSink)
    }

    /**
     * Returns all the transformers of the graph. A Transformer is a node that is neither a source nor a sink.
     *
     * @returns {Node[]}    The transformers of the graph.
     */
    getTransformers () {
        return this.nodes.filter(node => !node.isSource && !node.isSink)
    }

    /**
     * Returns all the used Notes
     *
     * @return {Node[]}
     */
    getUsedNodes () {
        return this.nodes.filter(node => node.color === GraphColoring.BLACK)
    }

    /**
     * Returns a List of all possible used Sources
     *
     * @return {Node[]}
     */
    getUsedSources () {
        return this.getSources().filter(node => node.color === GraphColoring.BLACK)
    }

    /**
     * Returns a List of all possible used Sinks
     *
     * @return {Node[]}
     */
    getUsedSinks () {
        return this.getSinks().filter(node => node.color === GraphColoring.BLACK)
    }


    /**
     * Returns a List of all possible used Transformers
     *
     * @return {Node[]}
     */
    getUsedTransformers () {
        return this.getTransformers().filter(node => node.color === GraphColoring.BLACK)
    }

    /**
     * Returns all the unused Nodes.
     *
     * @return {Node[]}
     */
    getUnusedNodes () {
        return this.nodes.filter(node => node.color !== GraphColoring.BLACK)
    }
    /**
     * Returns all the Sources that are not used.
     *
     * @returns {Node[]}
     */
    getUnusedSources () {
        return this.getSources().filter(node => node.color !== GraphColoring.BLACK)
    }

    /**
     * Returns all the Sinks that are not used.
     *
     * @returns {Node[]}
     */
    getUnusedSinks () {
        return this.getSinks().filter(node => node.color !== GraphColoring.BLACK)
    }

    /**
     * Returns all the Transformers that are not used.
     *
     * @returns {Node[]}
     */
    getUnusedTransformers () {
        return this.getTransformers().filter(node => node.color !== GraphColoring.BLACK)
    }

    /**
     * Colors the Graph based on the Input coloring of this and the reversed Graph.
     * Coloring will follow the following rules:
     * 1. If the Node is Black in both Graphs, it will be colored Black.
     * 2. If the Node is not Black in both Graphs, it will be colored White.
     */
    calculateColors () {
        // maybe there is a better solution than the reversed graph. till now, i didnt find one
        const reversedGraph = this.getReversedGraph()

        // color the graph
        this.calculateInputColors()
        reversedGraph.calculateInputColors()

        // get the useful nodes
        const usedNormalNodes = this.getUsedNodes()
        const usedReversedNodes = reversedGraph.getUsedNodes()
        const usedReversedNodesNames = usedReversedNodes.map(node => node.name)

        // just keep the nodes that are used in both graphs
        const usedNodes = usedNormalNodes.filter(node => usedReversedNodesNames.includes(node.name))

        // reset the colors
        this.nodes.forEach(node => node.color = GraphColoring.WHITE)

        // color the used nodes
        usedNodes.forEach(node => node.color = GraphColoring.BLACK)
    }

    /**
     * A function that calculates all the Colors of the nodes in the graph for the Input.
     * The colors are calculated by using a Depth First Search.
     * The following Rules Apply:
     * - All Nodes are White at the beginning
     * - If there is a Path between a Source, and a Sink, all Nodes on the Path get colored
     * - The color depends on the transformers in between the Source and the Sink
     * - If the relation is "OR", it is sufficient if the Node is used and
     * - If the relation is "AND", all the inputs have to be used
     *
     * All Nodes on the path will be colored according to the node with the "lowest" color on the path.
     * With the order "White < Gray < Black".
     */
    calculateInputColors () {
        // reset all colors
        this.nodes.forEach(node => node.reset())

        const sourceNodes = this.getSources()
        this.somethingWasChanged = true
        while (this.somethingWasChanged) {
            this.somethingWasChanged = false
            sourceNodes.forEach(sourceNode => {
                this.depthFirstSearchToSink(sourceNode, null)
            })
        }
    }


    /**
     * A function to perform a Depth First Search on the graph.
     *
     * @param {Node} currentNode          The current node to check.
     * @param {String} usedConnection     The connection that was used to get to the current node.
     * @param {String} minColorOnPath     The min color on the path to the current node.
     * @returns {any[]} failureReason     The reasons why one node cannot be used.
     *
     * @returns {String}                  The min color on the path to the current node.
     */
    depthFirstSearchToSink (currentNode, usedConnection, minColorOnPath = GraphColoring.BLACK) {
        // check if the current node is the target node/ a sink Node
        if (currentNode.isSink) {
            currentNode.color = getMaxColor(currentNode.color, minColorOnPath)
            return minColorOnPath
        }
        // check if the current node is black.
        // if it is black, we don't need to check it again
        if (currentNode.color === GraphColoring.BLACK) {
            return GraphColoring.BLACK
        }
        currentNode.visited += 1

        // set the input for the current Node
        currentNode.useInput(usedConnection)
        const maximumPossibleColor = currentNode.getPossibleColor()

        // if node is gray, set failure reason#
        // TODO

        // get the color for the next recursive step
        const nextColor = getMinColor(maximumPossibleColor, minColorOnPath)
        const prevColor = currentNode.color

        // Iterate over all Adjacent Nodes and color them
        const {adjacentRelations} = currentNode
        for (let i = 0; i < adjacentRelations.length; i++) {
            const adjacentNode = adjacentRelations[i].targetNode
            // one loop is okay for storages like supplier[1]->storage[1]->supplier[1]->supplier[2]
            if (adjacentNode.visited >= 2) {
                continue
            }
            const adjacentConnection = adjacentRelations[i].connection
            const color = this.depthFirstSearchToSink(adjacentNode, adjacentConnection, nextColor)
            currentNode.color = getMaxColor(currentNode.color, color)
        }
        if (prevColor !== currentNode.color) {
            this.somethingWasChanged = true
        }
        currentNode.visited -= 1
        return currentNode.color
    }

}


export default Graph