import EndEnergy from '../Objects/Energy/EndEnergy'
import NeededEnergy from '../Objects/Energy/NeededEnergy'
import Supplier from '../Objects/System/Supplier'
import Storage from '../Objects/System/Storage'
import Building from '@/scripts/Objects/Building';
import ObjectHelper from '../Helper/Object'
import Scenario from '@/scripts/Objects/Scenario';

import { BRUIWorkflow } from '../workflow';

class StateImporter {
    /**
     * Merges the data to import (toImport) with the current state.
     * @param {object} store The store to import the given data to
     * @param {object} toImport The data to import. Plain JS object, without instances.
     */
    static importState (store, toImport) {
        const stateCopy = ObjectHelper.cloneWithPrototype(store.state)
        const importCopy = ObjectHelper.clone(toImport)

        if (toImport.objectDescription && !toImport.climateData && !ObjectHelper.deepEqual(stateCopy.objectDescription.addressLatLng, toImport.objectDescription.addressLatLng)) {
            // override old climate data, because the object/site has changed. Only if location changed.
            importCopy.climateData = {}
        }

        if (toImport.climateData) {
            stateCopy.buildingGroups.forEach(group => {
                group.data.forEach(building => {
                    building.resetPrognoses()
                })
            })

            // TODO: We can get rid of this dependency, after implementing BRUI-632, because the step will know to execute itself after prognoses are resetted.
            BRUIWorkflow.invalidateStep(2, store, true)
        }

        if (toImport.buildingGroups) {
            importCopy.buildingGroups = toImport.buildingGroups.map((group) => {
                return {
                    ...group,
                    data: group.data.map((building) => {
                        const buildingObject = Building.deserialize(building)
                        if (toImport.objectEnergies && toImport.objectEnergies.length) {
                            buildingObject.setPrognosed()
                        }
                        return buildingObject
                    })
                }
            })
        }

        if (toImport.energyTypes) {
            importCopy.energyTypes = toImport.energyTypes.map(energy => NeededEnergy.deserialize(energy))
        }

        if (toImport.objectEnergies) {
            importCopy.objectEnergies = toImport.objectEnergies.map(energy => NeededEnergy.deserialize(energy))
        }

        if (toImport.scenarios && toImport.scenarioOptions && (store.state.energyTypes.length || importCopy.energyTypes)) {
            const neededEnergies = importCopy.energyTypes || store.state.energyTypes
            const allEnergies = [...neededEnergies, ...toImport.scenarioOptions.endEnergies.map(energy => EndEnergy.deserialize(energy))]
            importCopy.scenarios = importCopy.scenarios.map(scenario => Scenario.deserialize(scenario, allEnergies))

            importCopy.scenarioOptions.endEnergies = toImport.scenarioOptions.endEnergies.map(e => EndEnergy.deserialize(e))
            importCopy.scenarioOptions.supplier = toImport.scenarioOptions.supplier.map(s => Supplier.deserialize(s, allEnergies))
            importCopy.scenarioOptions.storages = toImport.scenarioOptions.storages.map(s => Storage.deserialize(s, allEnergies))
        }

        store.replaceState({ ...stateCopy, ...importCopy })
    }
}

export default StateImporter