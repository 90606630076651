import Vue from 'vue';
import VueI18n from 'vue-i18n';
import common from '../../translations/common';
import numbers from '../../translations/numbers';

/**
 * Plugin to auto format numbers depending on their type (integer, decimal).
 * Normally this would be a plugin on its own. But its heavily bound to i18n, so keep it in the i18n plugin context.
 */
const NumberAutoFormat = {
    install (vueClass) {
        vueClass.prototype.$numberAutoFormat = function (number) {
            if (isNaN(number)) {
                return number;
            }
            const format = Number.isInteger(number) ? 'integer' : 'decimal';
            return this.$n(number, format);
        }
    }
};

Vue.use(NumberAutoFormat);
Vue.use(VueI18n);

export default new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE,
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
    messages: common,
    silentTranslationWarn: true,
    numberFormats: numbers,
});