import ObjectHelper from '../Helper/Object'

/**
 * Class can export a given state into a object. In theory this only copies the given state tp a certain extend, defined by the given topics. Valid topics are:
 * - objectDescription: view1 description of the site/object and buildings (with their respective groups), state keys: objectDescription, buildingGroups
 * - climateData: state keys: climateData
 * - loadProfiles: state keys: buildingGroups
 * - scenarioConfigs: view3 scenarios wihtout results key, state keys: scenarios (modified)
 * - scenarioResults: state keys: scenarios
 */
class StateExporter {
    static exportState (state, topics) {
        const result = {}
        const stateCopy = ObjectHelper.cloneWithPrototype(state)

        // save the language in he export, so that on a reimport hte language can be set and no
        // conflicts between platform and data language occurs
        result.language = stateCopy.language

        topics.forEach(topicName => {
            switch (topicName) {
                case 'objectDescription':
                    result.objectDescription = stateCopy.objectDescription
                    topics.includes('loadProfiles') || (result.buildingGroups = this._deserializeBuildingGroups(state, false))
                    result.globalCustomAttributes = stateCopy.globalCustomAttributes
                    break
                case 'climateData':
                    result.climateData = stateCopy.climateData
                    break
                case 'loadProfiles':
                    result.buildingGroups = this._deserializeBuildingGroups(state)
                    result.energyTypes = stateCopy.energyTypes.map(energy => energy.serialize())
                    result.objectEnergies = stateCopy.objectEnergies.map(energy => energy.serialize())
                    break
                case 'scenarioConfigs':
                    // We need the neededEnergies, because a supplier/storage export doesn't contain the whole data for an energy to be deserialized (only their id as reference)
                    result.scenarioOptions = stateCopy.scenarioOptions
                    result.scenarioOptions.endEnergies = state.scenarioOptions.endEnergies.map(e => e.serialize())
                    result.scenarioOptions.supplier = state.scenarioOptions.supplier.map(s => s.serialize())
                    result.scenarioOptions.storages = state.scenarioOptions.storages.map(s => s.serialize())
                    result.scenarios = stateCopy.scenarios.map(s => s.serialize())
                    break
                case 'scenarioResults':
                    // TODO: Exporting ONLY scenarioResults will most likely lead to an error, because the entities are incomplete
                    result.scenarios = stateCopy.scenarios.map(s => {
                        return s.serialize()
                    })
                    break
            }
        })

        return result
    }

    static _deserializeBuildingGroups (state, withPrognoses = true) {
        return state.buildingGroups.map((group) => ({ ...group, data: group.data.map((building) => building.serialize(withPrognoses)) }));
    }
}

export default StateExporter