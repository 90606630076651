const Validators = {
    isPercentageValue (v) {
        return Validators.isNotNegative(v) && v <= 100
    },
    isNotNegative (v) {
        return v >= 0
    },
    isBetween (v, min, max) {
        return Validators.isMax(v, max) && Validators.isMin(v, min)
    },
    isMax (v, max) {
        return v <= max
    },
    isMin (v, min) {
        return v >= min
    },
    isNotEmpty (value) {
        return !!value || value === 0
    },
    isRelativeNumber (value) {
        return Validators.isNumeric(value) && Validators.isBetween(value, 0, 1)
    },
    isRelativeNumberOrAsterisk (value) {
        return Validators.isRelativeNumber(value) || value === '*'
    },
    isNumberOrAsterisk (value) {
        return Validators.isNumeric(value) || value === '*'
    },
    isNumeric (value) {
        return !isNaN(value)
    },
    isInList (value, list) {
        return list.includes(value)
    },
    isNotInList (value, list) {
        return !Validators.isInList(value, list)
    },
    isNumericOrInList (value, list) {
        return Validators.isNumeric(value) || Validators.isInList(value, list)
    },
    fvalidate (rule, message, params = []) {
        return (v) => {
            const valid = rule(v, ...params)
            return !valid && message ? message : valid
        }
    }
}

export default Validators