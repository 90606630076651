import Entity from './Entity';
import NumberUnit from './NumberUnit';

class Energy extends Entity {
    unit;
    costsEmission;
    costsEnergy;
    costsPerUnit;
    costsAltPerYearPercentage;
    co2PerUnit;
    primaryEnergy;
    demandBool;

    constructor (id, name, color, unit) {
        super(id, name, color);
        this.unit = unit;
    }

    serialize () {
        let serialized = super.serialize();
        serialized = {
            ...serialized,
            unit: this.unit,
            demandBool: this.demandBool
        };
        if (typeof this.costsEmission !== 'undefined' || typeof this.costsEnergy !== 'undefined') {
            serialized.costsPerUnit = {}
            typeof this.costsEmission !== 'undefined' && (serialized.costsPerUnit.costsEmission = Energy.serializeNumberUnit(this.costsEmission));
            typeof this.costsEnergy !== 'undefined' && (serialized.costsPerUnit.costsEnergy = Energy.serializeNumberUnit(this.costsEnergy));
        } else if (typeof this.costsPerUnit !== 'undefined') {
            serialized.costsPerUnit = Energy.serializeNumberUnit(this.costsPerUnit);
        }
        typeof this.costsAltPerYearPercentage !== 'undefined' && (serialized.costsAltPerYearPercentage = Energy.serializeNumberUnit(this.costsAltPerYearPercentage));
        typeof this.co2PerUnit !== 'undefined' && (serialized.co2PerUnit = Energy.serializeNumberUnit(this.co2PerUnit));
        typeof this.primaryEnergy !== 'undefined' && (serialized.primaryEnergy = this.primaryEnergy);

        return serialized;
    }

    /**
     * A Function that will only exist till Berta will be able to handle NumberUnits.
     *
     * @param {Object/NumberUnit} entry    The entry that should be serialized.
     * @returns {Object}
     */
    static serializeNumberUnit (entry) {
        if (!(entry instanceof NumberUnit)) {
            return new NumberUnit(entry['value'], '').serialize();
        }
        return entry.serialize();
    }

    static deserialize (object) {
        const energy = super.deserialize(...arguments);
        energy.unit = object.unit;
        // check if costsPerUnit is an object or a NumberUnit use the check if "value" is a property
        if (typeof object.costsPerUnit !== 'undefined' && typeof object.costsPerUnit.value !== 'undefined') {
            // then we have a numberUnit
            energy.costsPerUnit = NumberUnit.deserialize(object.costsPerUnit);
        } else if (typeof object.costsPerUnit !== 'undefined') {
            // we have multiple entries, speaking costsEnergy and costsEmission
            typeof object.costsPerUnit.costsEmission !== 'undefined' && (energy.costsEmission = NumberUnit.deserialize(object.costsPerUnit.costsEmission));
            typeof object.costsPerUnit.costsEnergy !== 'undefined' && (energy.costsEnergy = NumberUnit.deserialize(object.costsPerUnit.costsEnergy));
        }
        typeof object.costsAltPerYearPercentage !== 'undefined' && (energy.costsAltPerYearPercentage = NumberUnit.deserialize(object.costsAltPerYearPercentage));
        typeof object.co2PerUnit !== 'undefined' && (energy.co2PerUnit = NumberUnit.deserialize(object.co2PerUnit));
        typeof object.primaryEnergy !== 'undefined' && (energy.primaryEnergy = object.primaryEnergy);
        typeof object.demandBool !== 'undefined' && (energy.demandBool = object.demandBool);

        return energy;
    }
}

export default Energy;