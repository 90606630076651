import AbstractStep from './AbstractStep'
import RUDIAPIConnector from '../Connectors/RUDI/APIConnector'
import ObjectHelper from '../Helper/Object'
import ArrayHelper from '../Helper/Array'
import EndEnergy from '../Objects/Energy/EndEnergy'
import Supplier from '../Objects/System/Supplier'
import Storage from '../Objects/System/Storage'
import CustomAttribute from '../Objects/CustomAttribute'

class ScenarioOptions extends AbstractStep {
    name = 'ScenarioOptions'

    // eslint-disable-next-line class-methods-use-this
    validate () {
        return this.doBuildingSumsExist()
    }

    // eslint-disable-next-line class-methods-use-this
    async execute () {
        await this.saveScenarioOptions()
        this.appendEntityDataToScenarios()
    }

    // We dont want the options to be deleted, because they most likely stay the same in one session.
    // eslint-disable-next-line class-methods-use-this
    invalidate () {
        return true
    }

    async saveScenarioOptions () {
        if (Object.keys(this.store.state.scenarioOptions).length) {
            return
        }
        const apiConnector = new RUDIAPIConnector()
        const options = (await apiConnector.getOptions())._formScenario
        if (options.error) {
            throw new Error('Getting options from RUDI failed.')
        }


        let endEnergiesList = []
        let supplierList = []
        let storageList = []
        let globalStatDependencyList = []

        if (options.globalStatDependency && options.globalStatDependency._optionsGlobalStatDependency) {
            globalStatDependencyList = this._createGlobalStatDependencyListFromOptions(options.globalStatDependency._optionsGlobalStatDependency)
        }
        // suppliers need energies, so define energies first
        if (options.energy && options.energy._optionsNeededEnergy) {
            endEnergiesList = this._createEndEnergyListFromOptions(options.energy._optionsNeededEnergy)
        }

        // suppliers need all energies, so merge them with the needed energies from store
        const allEnergies = [...this.store.getters.allNeededEnergies, ...endEnergiesList]
            .map(e => ObjectHelper.cloneWithPrototype(e))
        if (options.supplier && options.supplier._optionsSupplier) {
            supplierList = this._createSystemListFromOptions(options.supplier._optionsSupplier, Supplier, allEnergies)
        }
        if (options.storage && options.storage._optionsStorage) {
            storageList = this._createSystemListFromOptions(options.storage._optionsStorage, Storage, allEnergies)
        }

        // reformat finances to more useful format
        const financeOptions = ScenarioOptions._reformatScenarioOptions(options.finances)
        const optimizationOptions = ScenarioOptions._reformatScenarioOptions(options.optimization)

        this.store.commit('scenarioOptions', {
            supplier: supplierList,
            storages: storageList,
            endEnergies: endEnergiesList,
            finances: { ...options.finances, ...financeOptions },
            optimization: { ...options.optimization, ...optimizationOptions },
            globalStatDependency: globalStatDependencyList
        })
    }

    // eslint-disable-next-line class-methods-use-this
    _createEndEnergyListFromOptions (options) {
        const endEnergiesList = []
        // Wording: In RUDI endEnergies are called neededEnergies. Our neededEnergies (Nutzenergien) don't have to do anything with RUDI.
        for (const energyKey in options) {
            const energyFromAPI = options[energyKey]
            const energy = EndEnergy.deserialize({...energyFromAPI, id: energyKey})

            endEnergiesList.push(energy)
        }

        return endEnergiesList
    }

    // eslint-disable-next-line class-methods-use-this
    _createSystemListFromOptions (options, systemClass, allEnergies) {
        const systemList = []
        for (const systemKey in options) {
            const systemFromAPI = options[systemKey]
            const system = systemClass.deserialize({...systemFromAPI, id: systemKey}, allEnergies)

            systemList.push(system)
        }
        return systemList
    }

    // eslint-disable-next-line class-methods-use-this
    _createGlobalStatDependencyListFromOptions (options) {
        const globalStatDependencyList = []
        const globalStatDependencyKeys = Object.keys(options)
        globalStatDependencyKeys.forEach((globalStatDependencyKey) => {
            globalStatDependencyList.push(new CustomAttribute(globalStatDependencyKey, options[globalStatDependencyKey].value, options[globalStatDependencyKey].unit))
        })
        return globalStatDependencyList
    }

    static _reformatScenarioOptions (optionsObject) {
        const options = {}
        for (const key in optionsObject) {
            const content = optionsObject[key]
            options[key] = {}

            if (content.hasOwnProperty('unit')) {
                options[key].unit = content.unit._default
            }
            if (content.hasOwnProperty('value')) {
                options[key].value = content.value._default
                options[key].displayName = content.value._displayName
            }
            if (content.hasOwnProperty('_default')) {
                options[key].value = content._default
            }
            if (content.hasOwnProperty('_displayName')) {
                options[key].displayName = content._displayName
            }
            if (content.hasOwnProperty('_options')) {
                options[key].options = content._options
            }
        }

        return options
    }

    /**
     * @TODO this can be replaced by implementing something with BRUI-276
     */
    appendEntityDataToScenarios () {
        this.store.state.scenarios.forEach((scenario, i) => {
            const entities = {}
            // needed energies might have changed in previous steps, e.g. when the prognosis of buildings/the site has changed.
            const neededEnergiesNeedUpdate = scenario.neededEnergies.length === 0 || !ArrayHelper.areEqual(this.store.state.objectEnergies.map(e => e.id), scenario.neededEnergies.map(e => e.id))

            entities.suppliers = scenario.suppliers.length > 0 ? scenario.suppliers : this.store.state.scenarioOptions.supplier.map(e => ObjectHelper.cloneWithPrototype(e))
            entities.storages = scenario.storages.length > 0 ? scenario.storages : this.store.state.scenarioOptions.storages.map(e => ObjectHelper.cloneWithPrototype(e))
            entities.endEnergies = scenario.endEnergies.length > 0 ? scenario.endEnergies : this.store.state.scenarioOptions.endEnergies.map(e => ObjectHelper.cloneWithPrototype(e))
            entities.neededEnergies = scenario.neededEnergies
            if (neededEnergiesNeedUpdate) {
                entities.neededEnergies = this.store.state.objectEnergies.map(objectEnergy => {
                    // in case a new energy was added
                    const energy = (scenario.neededEnergies && scenario.neededEnergies.find(e => e.id === objectEnergy.id)) || ObjectHelper.cloneWithPrototype(objectEnergy)
                    energy.values = [...objectEnergy.values]
                    return energy
                })
            }
            const objectLength = obj => Object.entries(obj).length;

            entities.usedSuppliers = objectLength(scenario.usedSuppliers) > 0 ? scenario.usedSuppliers : entities.suppliers.reduce((obj, item) => Object.assign(obj, { [item.id]: true }), {})
            // TODO: Storages are deactivated as of now, because they take ages to be calculated by RUDI. Activate them again, if RUDI fixed this.
            entities.usedStorages = objectLength(scenario.usedStorages) > 0 ? scenario.usedStorages : entities.storages.reduce((obj, item) => Object.assign(obj, { [item.id]: false }), {})
            entities.usedEndEnergies = objectLength(scenario.usedEndEnergies) > 0 ? scenario.usedEndEnergies : entities.endEnergies.reduce((obj, item) => Object.assign(obj, { [item.id]: true }), {})
            entities.usedNeededEnergies = scenario.usedNeededEnergies
            if (neededEnergiesNeedUpdate) {
                entities.usedNeededEnergies = entities.neededEnergies.reduce((obj, item) =>
                    Object.assign(obj, { [item.id]: typeof item.isUsable === 'function' ? item.isUsable(null, null, entities.suppliers) : true }), {})
            }

            this.store.commit('scenarioEntities', { index: i, ...entities })
        })
    }

    doBuildingSumsExist () {
        return this.store.state.objectEnergies && Array.isArray(this.store.state.objectEnergies) && this.store.state.objectEnergies.length > 0
    }
}

export default ScenarioOptions
