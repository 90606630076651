import AbstractStep from './AbstractStep'
import RUDIAPIConnector from '../Connectors/RUDI/APIConnector'

class ScenarioResults extends AbstractStep {
    name = 'ScenarioResults'
    // If true, 2 requests are triggered: 1st with plainResults, results will be written into scenario with previewResult = true. 2nd request without plainResult will override result.
    withPreview = true
    // Only takes effect when withPreview = false. Value of optimization.plainResults in the scenario request.
    withPlainResults = false

    // eslint-disable-next-line class-methods-use-this
    validate () {
        return this.doScenarioOptionsExist() && this.validateScenarios()
    }

    // eslint-disable-next-line class-methods-use-this
    async execute () {
        this.withPreview = this.store.state.scenarioCalculationOptions.withPreview
        this.withPlainResults = this.store.state.scenarioCalculationOptions.withPlainResults

        this.completeScenarios()
        if (this.withPreview) {
            await this.saveScenarioResults(true)
            this.saveScenarioResults(false)
        } else {
            await this.saveScenarioResults(this.withPlainResults)
        }
    }

    // todo: Needs some better implementation. At the moment we invalidate the scenario result in view3 itself, by deleting the result object of
    //  the scenario. In invalidate we may not want to delete the results of all scenarios. What can we do with invalidate here?
    // eslint-disable-next-line class-methods-use-this
    invalidate (params) {
        if (params && params.indexes) {
            params.indexes.forEach(i => {
                if (this.store.state.scenarios[i].result) {
                    this.store.commit('invalidateScenarioResult', i)
                }
            })
        } else {
            this.store.state.scenarios.forEach((scenario, i) => {
                if (this.store.state.scenarios[i].result) {
                    this.store.commit('invalidateScenarioResult', i)
                }
            })
        }

        return true
    }

    /**
     * Check if a scenario would be valid for further processing. If e.g. no supplier or energy usages are defined in the scenario, then the scenario is not valid
     * and also cannot be completed from the store later in the process. The function will return false in these cases.
     * @returns if the scenario is invalid.
     */
    validateScenarios () {
        for (let i = 0; i < this.store.state.scenarios.length; i++) {
            const scenarioToCheck = this.store.state.scenarios[i]

            if (!scenarioToCheck.usedEndEnergies || !scenarioToCheck.usedSuppliers || !scenarioToCheck.usedNeededEnergies) {
                return false
            }
        }

        return true
    }

    /**
     * Will add scenario data from the scenarioOptions in the store, when it is missing and neccessary to proceed. This can only be done for values, where we can
     * find information in the store for.
     */
    completeScenarios () {
        for (let i = 0; i < this.store.state.scenarios.length; i++) {
            const scenarioToCheck = this.store.state.scenarios[i]

            // this stuff should in theory been inserted by the user via the scenario card form. But check it anyway for safety.
            if (typeof scenarioToCheck.finances.interestOnCapitalPercentage === 'undefined' || scenarioToCheck.finances.interestOnCapitalPercentage === null) {
                scenarioToCheck.finances.interestOnCapitalPercentage = this.store.state.scenarioOptions.finances.interestOnCapitalPercentage.value
            }
            if (typeof scenarioToCheck.finances.periodOfTime === 'undefined' || scenarioToCheck.finances.periodOfTime === null) {
                scenarioToCheck.finances.periodOfTime = this.store.state.scenarioOptions.finances.periodOfTime
            }
            if (typeof scenarioToCheck.finances.costsEmissionCertificates === 'undefined' || scenarioToCheck.finances.costsEmissionCertificates === null) {
                scenarioToCheck.finances.costsEmissionCertificates = this.store.state.scenarioOptions.finances.costsEmissionCertificates
            }

            // optimization values may not come from the frontend scenario definition at all. But we can add all these from the store :)
            if (typeof scenarioToCheck.optimization === 'undefined' || scenarioToCheck.optimization === null) {
                scenarioToCheck.optimization = {}
            }
            if (typeof scenarioToCheck.optimization.optimizationTarget === 'undefined' || scenarioToCheck.optimization.optimizationTarget === null) {
                scenarioToCheck.optimization.optimizationTarget = this.store.state.scenarioOptions.optimization.optimizationTarget.value
            }
            if (!scenarioToCheck.optimization.calculationType) {
                scenarioToCheck.optimization.calculationType = this.store.state.scenarioOptions.optimization.calculationType.value
            }
            if (!scenarioToCheck.optimization.maxOveragePercentage) {
                scenarioToCheck.optimization.maxOveragePercentage = this.store.state.scenarioOptions.optimization.maxOveragePercentage.value
            }
            if (!scenarioToCheck.optimization.quantityOrDimension) {
                scenarioToCheck.optimization.quantityOrDimension = this.store.state.scenarioOptions.optimization.quantityOrDimension.value
            }
            if (!scenarioToCheck.optimization.secondsToWait) {
                scenarioToCheck.optimization.secondsToWait = this.store.state.scenarioOptions.optimization.secondsToWait.value
            }
        }
    }

    async saveScenarioResults (plainResults = false) {
        return new Promise(async (resolve, reject) => {
            const apiConnector = new RUDIAPIConnector()
            const { length } = this.store.state.scenarios;
            const { length: scenariosToRequestCount } = this.store.state.scenarios.filter((scenario) => this.scenarioNeedsRequest(scenario));
            if (scenariosToRequestCount === 0) {
                resolve()
            }

            let requestCount = 0;
            for (let i = 0; i < length; i++) {
                if (!this.scenarioNeedsRequest(this.store.state.scenarios[i])) {
                    continue
                }

                const reformattedGlobalCustomAttributes = {
                    'Bebaute-Grund-Fläche' : {
                        value : this.getBuiltUpArea(),
                        unit : 'm²'
                    },
                };
                // add the globalCustomAttributes as NumberUnit
                this.store.state.globalCustomAttributes
                    .filter(attribute => attribute.name && attribute.value)
                    .forEach((acc, attribute) => {
                        reformattedGlobalCustomAttributes[attribute.name] = {
                            value : attribute.value,
                            unit : attribute.unit ? attribute.unit : ''
                        }
                    })

                this.store.state.climateData.customData && this.store.state.climateData.customData.forEach(attribute => reformattedGlobalCustomAttributes[attribute.name] = attribute.value)
                this.store.state.scenarioOptions.globalStatDependency && this.store.state.scenarioOptions.globalStatDependency.forEach(attribute => reformattedGlobalCustomAttributes[attribute.name] = attribute.value)

                // deep copy the scenario, so we can modify it without changing the original one
                const scenario = Object.assign({}, this.store.state.scenarios[i])
                scenario.optimization.plainResults = plainResults

                apiConnector.getSupplierSetupForScenario(
                    i,
                    scenario,
                    this.store.state.objectEnergies,
                    this.store.state.climateData,
                    {
                        ...reformattedGlobalCustomAttributes
                    }
                ).then(async (result) => {
                    if (typeof result[i] !== 'undefined') {
                        result[i].sankey = await apiConnector.getSankeyHTML(i, result[i])
                        result[i] = this._transformScenarioResult(result, i);
                        result[i].previewResult = this.withPreview && plainResults
                        this.store.commit('scenarioResults', { index: i, result: result[i] })
                    }
                    requestCount++;
                    if (requestCount === scenariosToRequestCount) {
                        resolve();
                    }
                }).catch((e) => reject(e));
            }
        });

    }
    sumUsablePvArea () {
        const { buildingGroups } = this.store.state
        const buildings = buildingGroups.flatMap(el => el.data)
        return buildings.map(el => (el.area / el.floors) * 0.4).reduce((a, b) => a + b, 0)
    }

    getBuiltUpArea () {
        const { buildingGroups } = this.store.state
        const buildings = buildingGroups.flatMap(el => el.data)
        return buildings.map(el => el.area).reduce((a, b) => a + b, 0)
    }

    /**
     * Whether a scenario needs a request and is valid to be requested.
     * @param {*} scenario The scenario to check.
     * @returns {boolean} if the scenario should and can be requested from the API
     * TODO: should be part of the scenario object. See BRUI-276.
     */
    scenarioNeedsRequest (scenario) {
        return (scenario.result && scenario.result.previewResult) || (!scenario.result && this.allNeededEnergiesCovered(scenario))
    }
    allNeededEnergiesCovered (scenario) {
        if (!this.store.state.objectEnergies) {
            return false
        }
        const selectedEnergies = Object.keys(scenario.usedNeededEnergies).filter(energy => scenario.usedNeededEnergies[energy])

        return selectedEnergies.every(energy => {
            const [energyObject] = this.store.state.objectEnergies.filter(en => en.id === energy)
            // We dont need to cover energies, that are not needed by the object
            if (typeof energyObject === 'undefined') {
                return true
            }
            return energyObject.usableFn(energyObject, scenario, null, this.store.state.scenarioOptions.supplier)
        })
    }

    doScenarioOptionsExist () {
        return typeof this.store.state.scenarioOptions === 'object'
    }

    // eslint-disable-next-line class-methods-use-this
    _transformScenarioResult (result, index) {
        const resultCopy = Object.assign({}, result[index]);
        // transform result of energies to array
        Object.keys(result[index].energy).forEach((key) => {
            if (!('results' in resultCopy.energy[key])) {
                return
            }
            resultCopy.energy[key].results.data = Object.values(resultCopy.energy[key].results.data);
        });
        // transform result of supplier to array
        Object.keys(result[index].supplier).forEach((key) => {
            if (!('results' in resultCopy.supplier[key])) {
                return
            }
            // transform input
            Object.keys(result[index].supplier[key].results.input).forEach((inputKey) => {
                resultCopy.supplier[key].results.input[inputKey].data = Object.values(result[index].supplier[key].results.input[inputKey].data);
            });
            // transform output
            Object.keys(result[index].supplier[key].results.output).forEach((outputKey) => {
                resultCopy.supplier[key].results.output[outputKey].data = Object.values(result[index].supplier[key].results.output[outputKey].data);
            });

            // set id of supplier
            resultCopy.supplier[key].id = key;
        });

        // transform result of storages to array
        Object.keys(result[index].storage).forEach((key) => {
            if (!('results' in resultCopy.storage[key])) {
                return
            }
            // transform input
            Object.keys(result[index].storage[key].results.input).forEach((inputKey) => {
                resultCopy.storage[key].results.input[inputKey].data = Object.values(result[index].storage[key].results.input[inputKey].data);
            });
            // transform output
            Object.keys(result[index].storage[key].results.output).forEach((outputKey) => {
                resultCopy.storage[key].results.output[outputKey].data = Object.values(result[index].storage[key].results.output[outputKey].data);
            });

            // set id of storage
            resultCopy.storage[key].id = key;
        });


        return resultCopy;
    }
}

export default ScenarioResults
