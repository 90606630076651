/* eslint-disable */
import Scenario from "@/scripts/Objects/Scenario";

export default [
    Scenario.deserialize({
        name: 'Min. Invest',
        optimization: {
            optimizationTarget: 'Investitionskosten',
            quantityOrDimension: 'Auslegung',
            maxOveragePercentage: 0.05,
            nominalPrimaryEnergy: '*'
        },
        finances: {
            interestOnCapitalPercentage: 0.03,
            periodOfTime: { value: 25, unit: 'a' },
            costsEmissionCertificates: { value: 0, unit: '€/kgCO₂' }
        },
    }),
    Scenario.deserialize({
        name: process.env.VUE_APP_I18N_LOCALE === 'de' ? 'Gesamtkosten im Betrachtungszeitraum' : 'Total costs of ownership',
        optimization: {
            optimizationTarget: 'Total Costs of Ownership',
            quantityOrDimension: 'Auslegung',
            maxOveragePercentage: 0.05,
            nominalPrimaryEnergy: '*'
        },
        finances: {
            interestOnCapitalPercentage: 0.03,
            periodOfTime: { value: 25, unit: 'a' },
            costsEmissionCertificates: { value: 0, unit: '€/kgCO₂' }
        },
    }),
    Scenario.deserialize({
        name: 'Min. CO₂',
        optimization: {
            optimizationTarget: 'CO2-Emission',
            quantityOrDimension: 'Auslegung',
            maxOveragePercentage: 0.05,
            nominalPrimaryEnergy: '*'
        },
        finances: {
            interestOnCapitalPercentage: 0.03,
            periodOfTime: { value: 25, unit: 'a' },
            costsEmissionCertificates: { value: 0, unit: '€/kgCO₂' }
        },
    })
]