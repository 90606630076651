<i18n>
{
    "en": {
        "step1": "Site",
        "step2": "Energy requirements",
        "step3": "Variants",
        "step4": "Evaluation",
        "toStep4": "quick calculation",
        "appOptions": "App options"
    },
    "de": {
        "step1": "Liegenschaft",
        "step2": "Energiebedarf",
        "step3": "Varianten",
        "step4": "Auswertung",
        "toStep4": "Schnellauslegung",
        "appOptions": "Anwendungsoptionen"
    }
}
</i18n>

<template>
    <v-app id="app">
        <v-main>
            <stepper id="stepper" :steps="steps" />
            <div class="font-weight-bold text-xl-h4 text-md-h5">
                {{ steps[currentPage].name }}
            </div>
            <router-view class="step-view" @showLoadingScreen="onShowLoadingScreen" @hideLoadingScreen="showLoadingScreen = false" />
            <v-container v-if="!showLoadingScreen" class="navigation-footer">
                <div>
                    <v-btn v-show="currentPage > 0" color="primary" @click="stepRouter(-1)">
                        <v-icon>fa-angle-left</v-icon>
                        &nbsp;{{ $t("common.buttons.previousPage") }}
                    </v-btn>
                </div>
                <div class="forward-navigation-buttons">
                    <v-btn
                        v-show="currentPage !== steps.length - 1" color="primary"
                        :disabled="!workflow.validateStep(Math.max(...pageStepMap[arrayHelper.getIndexInBounds(pageStepMap, currentPage)]), $store)"
                        @click="stepRouter()"
                    >
                        {{ $t("common.buttons.nextPage") }}&nbsp;
                        <v-icon>fa-angle-right</v-icon>
                    </v-btn>
                    <v-btn v-show="currentPage < steps.length - 2" color="primary" :disabled="!workflow.validateSpecificSteps([0, 1, 2], $store)"
                        @click="stepRouter(steps.length - 1 - currentPage)"
                    >
                        {{ $t('toStep4') }}&nbsp;
                        <v-icon>fa-angle-double-right</v-icon>
                    </v-btn>
                </div>
            </v-container>
        </v-main>
        <application-options-drawer class="application-options-drawer" />
        <development-options-drawer v-if="showDevDrawer" class="development-options-drawer" />
        <loading-overlay :show-loading-screen="showLoadingScreen" :loading-text="loadingScreenParams.loadingText" :snake-enabled="currentPage === 3"
            :additional-text="loadingScreenParams.additionalText" :images="loadingScreenParams.images"
        />
        <store-translate />
    </v-app>
</template>


<script>
import Stepper from '@/components/Stepper.vue'
import LoadingOverlay from '@/components/LoadingOverlay'
import ApplicationOptionsDrawer from '@/components/ApplicationOptionsDrawer'
import DevelopmentOptionsDrawer from '@/components/DevelopmentOptionsDrawer'
import { BRUIWorkflow } from './scripts/workflow'
import StoreTranslate from '@/components/StoreTranslate';
import ArrayHelper from './scripts/Helper/Array'

export default {
    name: 'App',
    components: {
        Stepper,
        LoadingOverlay,
        ApplicationOptionsDrawer,
        DevelopmentOptionsDrawer,
        StoreTranslate
    },
    data () {
        return {
            workflow: BRUIWorkflow,
            showLoadingScreen: false,
            loadingScreenParams: {},
            pageStepMap: [[0, 1], [2], [3], [4]],
            showDevDrawer: process.env.VUE_APP_ENV !== 'production',
            arrayHelper: ArrayHelper
        }
    },
    computed: {
        currentPage () {
            return this.$store.state.currentPageIndex
        },
        currentLanguage () {
            return this.$store.state.language
        },
        // has to be computed to refresh on language switch
        steps () {
            return [
                {
                    name: this.$t('step1'),
                    url: '/location',
                    valid: true,
                },
                {
                    name: this.$t('step2'),
                    url: '/energyrequirement',
                    valid: this.workflow.validateStep(Math.max(this.pageStepMap[1]), this.$store),
                },
                {
                    name: this.$t('step3'),
                    url: '/variants',
                    valid: this.workflow.validateStep(Math.max(this.pageStepMap[1]), this.$store),
                },
                {
                    name: this.$t('step4'),
                    url: '/evaluation',
                    valid: this.workflow.validateStep(Math.max(this.pageStepMap[1]), this.$store),
                }
            ]
        }
    },
    watch: {
        currentLanguage () {
            this.workflow.executeSingleStep(1, this.$store)
        }
    },
    methods: {
        onShowLoadingScreen (params = {}) {
            this.showLoadingScreen = true
            this.loadingScreenParams = params
        },
        stepRouter (amount = 1) {
            this.$store.commit('currentPageIndex', this.$store.state.currentPageIndex + amount)
        }
    }
};
</script>

<style>
html {
    overflow-y: auto;
}
</style>

<style scoped lang="scss">
#app {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;

    .application-options-drawer {
        position: fixed;
        top: 10% !important;
        left: 100%;
        transform: translate(-100%, 0) !important;
        z-index: 998;
    }

    .development-options-drawer {
        position: fixed;
        top: 40% !important;
        left: 100%;
        transform: translate(-100%, 0) !important;
        z-index: 998;
    }

    #stepper {
        position: sticky;
        top: 0;
        z-index: 999;
        margin-bottom: 50px;
    }

    .navigation-footer {
        display: flex;
        gap: 20px;
        justify-content: center;

        .forward-navigation-buttons {
            display: flex;
            gap: 10px;
            margin-left: auto;
        }
    }
}
</style>
