<i18n>
{
    "en": {
        "heading": "Development options"
    },
    "de": {
        "heading": "Entwickleroptionen"
    }
}
</i18n>

<template>
    <v-navigation-drawer class="options-drawer" :mini-variant.sync="collapsed" permanent>
        <v-list-item class="px-2 debug">
            <v-list-item-icon @click="collapsed = !collapsed">
                <v-icon small>
                    {{ collapsed ? 'fa-chevron-left' : 'fa-chevron-right' }}
                </v-icon>&nbsp;
                <v-icon>fa-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('heading') }}</v-list-item-title>
        </v-list-item>
        <v-divider />
        <v-list-item link>
            <v-list-item-icon class="drawer-icon">
                <v-icon>fa-solid fa-bug</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-checkbox v-model="withPreview" label="Preview Mode" @change="onPreviewModeChange" />
                <v-checkbox v-model="withPlainResults" :disabled="withPreview" label="Plain Results" @change="onPlainResultsChange" />
                <v-btn @click="setDebugLocation">
                    <v-icon>fa-map-marker-alt</v-icon>&nbsp;Debug Ort setzen
                </v-btn>
            </v-list-item-content>
        </v-list-item>
    </v-navigation-drawer>
</template>

<script>

export default {
    data () {
        return {
            collapsed: true,
            withPreview: this.$store.state.scenarioCalculationOptions.withPreview,
            withPlainResults: this.$store.state.scenarioCalculationOptions.withPlainResults,
        }
    },
    methods: {
        setDebugLocation () {
            const lat = Math.random() * (53.77 - 47.82 + 1) + 47.82
            const lng = Math.random() * (12.07 - 8.24 + 1) + 8.24
            const address = { addressLatLng: { lat, lng }, address: `DEBUG LOCATION at lat:${lat};lng:${lng}` }
            this.$store.commit('objectDescription', address)
        },
        onPreviewModeChange (value) {
            this.$store.commit('withPreview', value)
        },
        onPlainResultsChange (value) {
            this.$store.commit('withPlainResults', value)
        }
    }
}
</script>

<style lang="scss" scoped>
.options-drawer {
    height: fit-content !important;
    width: fit-content !important;
    background-color: white !important;
    border: 1px solid #eee;
    border-radius: 4px;
    padding: 5px;

    .debug {
        background: #ff000099;
        border-radius: 4px;
    }

    .drawer-icon {
        margin-top: 24px;
        max-width: 25px;
    }
}
</style>