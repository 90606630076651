import AbstractStep from './AbstractStep'
import BERTAAPIConnector from '../Connectors/BERTA/APIConnector'

class BuildingTypes extends AbstractStep {
    name = 'BuildingTypes'
    lastFetchedLanguage;

    // eslint-disable-next-line class-methods-use-this
    validate () {
        return true
    }

    async execute () {
        // building types only have to be re-requested only language change because they most likely dont change during one session (very static data)
        this.lastFetchedLanguage !== this.store.state.language && await this.saveBuildingTypes()
    }

    // Nothing to invalidate.
    // eslint-disable-next-line class-methods-use-this
    invalidate () {
        return true
    }

    async saveBuildingTypes () {
        // Maps language from the store to languages valid in the BERTA buildingTypes endpoint
        const languageMap = {
            'en': 'EN',
            'de': 'DE'
        }
        const apiConnector = new BERTAAPIConnector();
        const buildingTypesData = await apiConnector.requestAllBuildingtypes(languageMap[this.store.state.language] || 'EN');
        this.lastFetchedLanguage = this.store.state.language
        this.store.commit('buildingTypes', buildingTypesData)
    }
}

export default BuildingTypes