import Building from '@/scripts/Objects/Building';
import { v4 as uuidv4 } from 'uuid'

export default {
    createBuildingInGroup (store, { data, groupIndex }) {
        const { objectDescription } = store.state;
        const { lat, lng } = objectDescription.addressLatLng;
        const { name: city } = objectDescription;
        const location = data.location || { lat, lng, city };
        const building = new Building(data.name, data.type, location, data.floors, data.area, null, {}, data.id);
        store.commit('addBuildingToGroup', { building, groupIndex });
    },

    removeBuildingFromGroup (store, { buildingId, groupIndex }) {
        const building = store.getters.getBuildingById(buildingId);
        if (building) {
            store.commit('removeBuildingFromGroup', { building, groupIndex });
        }
    },

    updateBuilding (store, { buildingId, data }) {
        const building = store.getters.getBuildingById(buildingId);
        if (building) {
            store.commit('updateBuilding', { building, data });
        }
    },

    updateAllBuildingLocations (store, location) {
        const { allBuildings } = store.getters;

        allBuildings.forEach(building => {
            store.commit('updateBuildingLocation', { building, location })
        });
    },

    setBuildingPrognosis (store, { buildingId, prognosis }) {
        const building = store.getters.getBuildingById(buildingId);
        if (building) {
            store.commit('setBuildingPrognosis', { building, prognosis });
        }
    },

    /**
     * reset the prognosis to the original prognosis
     * @param {object} store vuex store
     * @param {number} buildingId id of the building
     */
    resetBuildingPrognosis (store, { buildingId }) {
        const building = store.getters.getBuildingById(buildingId);
        if (building) {
            store.commit('resetPrognosis', { prognosis: building.prognosis });
        }
    },

    addGlobalCustomAttribute (store, attribute) {
        const { globalCustomAttributes } = store.state;
        const duplicateIndexInStore = globalCustomAttributes.map(a => a.name).lastIndexOf(attribute.name)
        if (duplicateIndexInStore === -1) {
            attribute.id = uuidv4();
            store.commit('addGlobalCustomAttribute', attribute);
        }
    },

    updateGlobalCustomAttribute (store, { attribute, changes }) {
        const { globalCustomAttributes } = store.state;
        let duplicateIndexInStore = -1;
        let possibleAttributeToDelete = null;

        if (changes.name) {
            duplicateIndexInStore = globalCustomAttributes.map((attr) => attr.name).lastIndexOf(changes.name);
            possibleAttributeToDelete = globalCustomAttributes.find((attr) => attr.id === attribute.id);
        }

        if (duplicateIndexInStore === -1) {
            store.commit('updateGlobalCustomAttribute', { attribute, changes });
        } else if (possibleAttributeToDelete) {
            store.commit('deleteGlobalCustomAttribute', possibleAttributeToDelete);
        }
    },
}