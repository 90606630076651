import ObjectHelper from '@/scripts/Helper/Object';
import ColorHelper from '@/scripts/Helper/Color';
import Node from '@/scripts/Algorithms/DependencyValidator/Node';
import Graph from '@/scripts/Algorithms/DependencyValidator/Graph';
import Supplier from '@/scripts/Objects/System/Supplier';
import Storage from '@/scripts/Objects/System/Storage';
import EndEnergy from '@/scripts/Objects/Energy/EndEnergy';
import NeededEnergy from '@/scripts/Objects/Energy/NeededEnergy';

/**
 * @TODO Use this object to implement BRUI-276
 */
class Scenario {

    name = 'Min. Invest';

    optimization = {
        optimizationTarget: 'Investitionskosten',
        optimizationTargetWeighting: {},
        quantityOrDimension: 'Auslegung',
        maxOveragePercentage: 0.05,
        nominalPrimaryEnergy: '*'
    };

    finances = {
        interestOnCapitalPercentage: 0.03,
        periodOfTime: {value: 15, unit: 'a'},
        costsEmissionCertificates: {value: 0, unit: '€/kgCO₂'}
    };

    result = undefined;

    usedSuppliers = {};
    usedEndEnergies = {};
    usedNeededEnergies = {};
    usedStorages = {};

    color = '';

    constructor (suppliers, endEnergies, neededEnergies, storages) {
        this.suppliers = (suppliers || []).map(supplier => ObjectHelper.cloneWithPrototype(supplier));
        this.endEnergies = (endEnergies || []).map(endEnergy => ObjectHelper.cloneWithPrototype(endEnergy));
        this.neededEnergies = (neededEnergies || []).map(neededEnergy => ObjectHelper.cloneWithPrototype(neededEnergy));
        this.storages = (storages || []).map(storage => ObjectHelper.cloneWithPrototype(storage));

        this.suppliers.forEach(supplier => (this.usedSuppliers[supplier.id] = true));
        this.endEnergies.forEach(endEnergy => (this.usedEndEnergies[endEnergy.id] = true));
        this.neededEnergies.forEach(neededEnergy => (this.usedNeededEnergies[neededEnergy.id] = true));
        this.storages.forEach(storage => (this.usedStorages[storage.id] = true));

        this.dependencyGraph = null;
        this.buildUpDependencyGraph();

        this.color = ColorHelper.getRandomHexColor('ff');
    }

    clone () {
        // clone without serialize and deserialize
        const scenario = new Scenario(this.suppliers, this.endEnergies, this.neededEnergies, this.storages);
        scenario.name = this.name;
        scenario.color = this.color;
        scenario.optimization = ObjectHelper.clone(this.optimization);
        scenario.finances = ObjectHelper.clone(this.finances);
        scenario.usedSuppliers = {...this.usedSuppliers};
        scenario.usedEndEnergies = {...this.usedEndEnergies};
        scenario.usedNeededEnergies = {...this.usedNeededEnergies};
        scenario.usedStorages = {...this.usedStorages};
        scenario.result = ObjectHelper.clone(this.result);

        scenario.buildUpDependencyGraph();
        return scenario;
    }

    /**
     * A Function that returns a Scenario Object from a given JSON Object
     *
     * @param {object} json         A JSON Object that represents a Scenario
     * @param {object[]} energies   The Energies of the System in the Store
     * @return {Scenario}           A Scenario Object
     */
    static deserialize (json, energies = null) {
        const deserializedSuppliers = (json.suppliers || []).map(supplier => Supplier.deserialize(supplier, energies));
        const deserializedStorages = (json.storages || []).map(storage => Storage.deserialize(storage, energies));
        const deserializedEndEnergies = (json.endEnergies || []).map(endEnergy => EndEnergy.deserialize(endEnergy));
        const deserializedNeededEnergies = (json.neededEnergies || []).map(neededEnergy => NeededEnergy.deserialize(neededEnergy));

        const scenario = new Scenario(deserializedSuppliers, deserializedEndEnergies, deserializedNeededEnergies, deserializedStorages);
        scenario.name = json.name || scenario.name;
        scenario.color = json.color || scenario.color;
        scenario.optimization = json.optimization || scenario.optimization;
        scenario.finances = json.finances || scenario.finances;

        scenario.usedSuppliers = json.usedSuppliers || scenario.usedSuppliers;
        scenario.usedEndEnergies = json.usedEndEnergies || scenario.usedEndEnergies;
        scenario.usedNeededEnergies = json.usedNeededEnergies || scenario.usedNeededEnergies;
        scenario.usedStorages = json.usedStorages || scenario.usedStorages;

        scenario.result = json.result || scenario.result;

        return scenario
    }

    /**
     * A Method that serializes the Scenario Object to a JSON Object
     *
     * @return {Object}
     */
    serialize () {
        const serialized = {
            name: ObjectHelper.clone(this.name),
            optimization: ObjectHelper.clone(this.optimization),
            finances: ObjectHelper.clone(this.finances),
            suppliers: this.suppliers.map(supplier => supplier.serialize()),
            endEnergies: this.endEnergies.map(endEnergy => endEnergy.serialize()),
            neededEnergies: this.neededEnergies.map(neededEnergy => neededEnergy.serialize()),
            storages: this.storages.map(storage => storage.serialize()),
            usedSuppliers: ObjectHelper.clone(this.usedSuppliers),
            usedEndEnergies: ObjectHelper.clone(this.usedEndEnergies),
            usedNeededEnergies: ObjectHelper.clone(this.usedNeededEnergies),
            usedStorages: ObjectHelper.clone(this.usedStorages),
            result: ObjectHelper.clone(this.result),
            color: this.color
        }
        return serialized;
    }


    /**
     * A Method that returns the names/ ids of the used entities
     *
     * @param {object[]} entities         Either usedSuppliers, usedEndEnergies, usedNeededEnergies or usedStorages
     * @return {string[]}
     */
    // eslint-disable-next-line class-methods-use-this
    getUsedEntities (entities) {
        if (typeof entities !== 'object') {
            return [];
        }
        return Object.entries(entities)
            .filter(([, value]) => value)
            .map(([key]) => key.toString());
    }

    /**
     * A Method that builds up the dependency graph. Which is used to determine if an entity can be used
     * or not.
     *
     * @return {void}
     */
    buildUpDependencyGraph () {
        const graph = new Graph();

        const usedNeededEnergies = this.getUsedEntities(this.usedNeededEnergies);
        const usedEndEnergies = this.getUsedEntities(this.usedEndEnergies);
        const usedSuppliers = this.getUsedEntities(this.usedSuppliers);
        const usedStorages = this.getUsedEntities(this.usedStorages);

        this.neededEnergies && this.neededEnergies
            // currently needed energy name and id aren't the same, we need to change that as soon as the id
            // corresponds to the energy
            .filter(neededEnergy => usedNeededEnergies.includes(neededEnergy.id.toString()))
            .forEach(neededEnergy => {
                graph.addNode(new Node(neededEnergy.id.toString(), [neededEnergy.name], [], false, true))
            });

        this.suppliers && this.suppliers
            .filter(supplier => usedSuppliers.includes(supplier.id))
            .forEach(supplier => {
                graph.addNode(new Node(supplier.id.toString(), supplier.getInputNames(), supplier.getOutputNames(), false, false, supplier.getInputRelation(), supplier.getOutputRelation()))
            });

        this.storages && this.storages
            .filter(storage => usedStorages.includes(storage.id))
            .forEach(storage => {
                graph.addNode(new Node(storage.id.toString(), storage.getInputNames(), storage.getOutputNames(), false, false, storage.getInputRelation(), storage.getOutputRelation()))
            });

        this.endEnergies && this.endEnergies
            .filter(endEnergy => usedEndEnergies.includes(endEnergy.id))
            .forEach(endEnergy => {
                graph.addNode(new Node(endEnergy.id.toString(), [], [endEnergy.name], true, false))
            });

        graph.buildEdges();
        graph.calculateColors();
        this.dependencyGraph = graph;
    }

    /**
     * A Method that returns if the given Entity (supplier, endEnergy, neededEnergy or storage) is can be used
     *
     * @param {object} entity   Either a supplier, endEnergy, neededEnergy or storage
     * @return {boolean}        True if the entity can be used, false otherwise
     */
    isEntityUsable (entity) {
        const usedNames = this.dependencyGraph.getUsedNodes().map(node => node.name);
        return usedNames.includes(entity.id.toString());
    }
}

export default Scenario;