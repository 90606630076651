import AbstractAPIConnector from '../AbstractAPIConnector'
import APIError from '../APIError';
import i18n from '../../../plugins/i18n';

class RUDIAPIConnector extends AbstractAPIConnector {
    APIUrl = process.env.VUE_APP_RUDI_API_URL

    // todo: later this template should prob. come from the caller of the connector, with pre-filled values.
    //  But it makes more sense to do this when implementing the forms for the values below.
    template = {
        'optimization': {
            'optimizationTarget': 'Total Costs of Ownership', // this will get set from the scenario
            'quantityOrDimension': 'Auslegung',
            'calculationType': 'performant',
            'secondsToWait': 300,
            'maxOveragePercentage': '*'
        },
        'finances': {
            'interestOnCapitalPercentage': 0.03, // this will get set from the scenario
            'periodOfTime': 25, // this will get set from the scenario
            'costsEmissionCertificates': {
                value: 0.05,
                unit: '€/kgCO₂'
            },
        },
        'globalStatDependency': {}, // this will get set from the scenario, and data from the store (needs to be passed)
        'supplier': {}, // this will get set from the scenario, and data from the store (needs to be passed)
        'energy': {}, // this will get set from the scenario, and data from the store (needs to be passed)
        'inputData': {
            'demand': {}, // this will be data from the store (passed to here)
            'dependency': {} // these will be data from the store (passed to here)
        }
    }

    // TODO: This is temporary, until target function weighting in RUDI is fixed.
    defaultWeightings = {
        'Total Costs of Ownership': {
            'InvestPercentage': 0.0,
            'TCOPercentage': 1.0,
            'CO2Percentage': 0.0
        },
        'Investitionskosten': {
            'InvestPercentage': 0.99,
            'TCOPercentage': 0.01,
            'CO2Percentage': 0.0
        },
        'CO2-Emission': {
            'InvestPercentage': 0.0,
            'TCOPercentage': 0.01,
            'CO2Percentage': 0.99
        }
    }

    async getOptions () {
        const requestURL = `${this.APIUrl}/api/options`
        const jsonData = await this._fetchJSONFromURL(requestURL)
        return jsonData
    }

    async getSupplierSetupForScenario (scenarioId, scenario, demandData, climateData, globalStatDependency) {
        if (!this._checkDataCompleteForRequest(scenario, scenario.suppliers, scenario.endEnergies, demandData, climateData)) {
            return false
        }

        const requestURL = `${this.APIUrl}/api/calculation`

        const inputData = {}
        inputData[scenarioId] =
            this._prepareInputDataForCalculation(scenario, demandData, climateData, globalStatDependency)

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(inputData)
        };
        const jsonData = await this._fetchJSONFromURL(requestURL, requestOptions, true)
        return jsonData
    }

    /**
     * Prepares the input data object that is later send to RUDI. Extends/Overrides this.template.
     * @param {object} scenario The scenario which should be requested by RUDI. This should contain the selected Nutzenergien, Erzeuger and Endenergien.
     * @param {object} demandData The demandData for the building/site for the scenario. An array of objects from BERTA (with name, type, unit and values props)
     * @param {object} climateData The climateData from the weather API (with at least Außentemperatur and hor. Globalstrahlung props)
     * @param {object} globalStatDependency An object that will be merged with template.globalStatDependency. Parameter props will override template probs
     * @returns The extended/overriden inputData object
     */
    _prepareInputDataForCalculation (scenario, demandData, climateData, globalStatDependency) {
        // supplier (part of scenario, details in RUDI options). Basically gets all RUDI data for a supplier for all selected suppliers in the scenario
        let supplier = scenario.suppliers.filter(s =>
            Object.keys(scenario.usedSuppliers).filter(k => scenario.usedSuppliers[k]).includes(s.id)).map(s => s.serialize())
        let storages = scenario.storages.filter(s =>
            Object.keys(scenario.usedStorages).filter(k => scenario.usedStorages[k]).includes(s.id)).map(s => s.serialize())

        supplier = supplier.reduce((obj, item) => Object.assign(obj, { [item.id]: item }), {})
        storages = storages.reduce((obj, item) => Object.assign(obj, { [item.id]: item }), {})

        // end energies (part of scenario, details in RUDI options). Basically gets all RUDI data for a end energy for all selected end energies in the scenario
        let energies = scenario.endEnergies.filter(s =>
            Object.keys(scenario.usedEndEnergies).filter(k => scenario.usedEndEnergies[k]).includes(s.id)).map(e => e.serialize())
        energies = energies.reduce((obj, item) => Object.assign(obj, { [item.id]: item }), {})

        const demandedEnergies = {}
        // The usedEnergies that are marked as true in a scenario, only these are respected as demandEnergies for RUDI
        const selectedDemandEnergies = Object.keys(scenario.usedNeededEnergies).filter(energy => scenario.usedNeededEnergies[energy]).map(el => parseInt(el))
        // Add all demand energies (that were selected) to energies and add their load profiles to the demandEnergies object.
        demandData.forEach(neededEnergy => {
            if (selectedDemandEnergies.includes(neededEnergy.id)) {
                energies[neededEnergy.id] = neededEnergy.serialize()
                // smaller size of input data, we dont need the values here anyway
                delete energies[neededEnergy.id].values
                demandedEnergies[neededEnergy.id] = neededEnergy.values
            }
        })
        // Copy the template and fill the copy with all relevant data
        const inputData = JSON.parse(JSON.stringify(this.template))
        // With fallback to template if scenario.optimization is missing
        inputData.optimization = scenario.optimization || inputData.optimization
        inputData.optimization.optimizationTarget = scenario.optimization.optimizationTarget
        inputData.optimization.optimizationTargetWeighting = scenario.optimization.optimizationTargetWeighting

        // With fallback to template if scenario.finances is missing
        inputData.finances = scenario.finances || inputData.finances

        inputData.globalStatDependency = this.template.globalStatDependency
        for (const [key, value] of Object.entries(globalStatDependency)) {
            if (!Array.isArray(value)) {
                inputData.globalStatDependency[key] = value
            } else {
                inputData.inputData.dependency[key] = value
            }
        }
        inputData.supplier = supplier
        inputData.storage = storages
        inputData.energy = energies
        inputData.inputData.demand = demandedEnergies
        inputData.inputData.dependency[climateData.data.t.id] = climateData.data.t.values
        inputData.inputData.dependency[climateData.data.B.id] = climateData.data.B.values
        inputData.inputData.dependency[climateData.data.WG.id] = climateData.data.WG.values
        inputData.inputData.dependency[climateData.data.D.id] = climateData.data.D.values
        inputData.name = scenario.name

        return inputData
    }

    // eslint-disable-next-line class-methods-use-this
    _checkDataCompleteForRequest (scenario, availableSupplier, availableEnergies, demandData, climateData) {
        return typeof scenario === 'object'
            && Array.isArray(availableSupplier) && availableSupplier.length > 0
            && Array.isArray(availableEnergies) && availableEnergies.length > 0
            && Array.isArray(demandData) && demandData.length > 0
            && typeof climateData === 'object'
    }

    async getSankeyHTML (scenarioId, forResult) {
        const requestURL = `${this.APIUrl}/api/visualization`
        forResult.optimization.sankeyWithTable = false
        const reqBody = {}
        reqBody[scenarioId] = forResult

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(reqBody)
        };
        const jsonData = await this._fetchJSONFromURL(requestURL, requestOptions)

        return jsonData[scenarioId].optimization.results.sankey
    }

    /**
     * A Function to fetch a JSON from a URL
     *
     * @param {String}  url                     The URL to fetch the JSON from
     * @param {Object}  requestOptions          The request options to use for the fetch
     * @param {Boolean} keepAlive               If the fetch should be kept alive
     * @returns {Promise<*|void|T>}             The fetched JSON
     * @private
     */
    async _fetchJSONFromURL (url, requestOptions, keepAlive = false) {
        const error_handler = (response) => {
            if (response.status < 200 || response.status > 299) {
                RUDIAPIConnector.apiErrorHandler(response)
            }
            return response
        }

        if (!keepAlive) {
            // If the Streaming request is not a keep-alive connection, just use the normal fetch
            return super._fetchJSONFromURL(url, requestOptions).then(error_handler)
        } else {
            // If the Streaming request is a keep-alive connection, use the fetch with keep-alive
            return this._fetchJSONFromURLKeepAlive(url, requestOptions).then(error_handler)
        }
    }
    /**
     * Evaluates RUDI error types and throws error messages accordingly
     * @param {Object} apiError error to handle (response.json)
     */
    static apiErrorHandler (apiError) {
        let errorApiObject = {}
        let errorText = i18n.t('common.notifications.unknownError')
        let errorCode = ''
        if (typeof apiError === 'object') {
            errorApiObject = apiError
        }

        if (typeof apiError.detail !== 'undefined') {
            if (typeof apiError.detail.statuscode !== 'undefined') {
                errorCode = `${apiError.detail.statuscode}`
            }
        } else if (typeof apiError.status !== 'undefined') {
            errorCode = `${apiError.status}`
        }

        // errorCode parsing
        if (Object.keys(i18n.t('rudiError.badRequestError')).includes(errorCode.replace(/[0-9]*$/, ''))) {
            // if a message only exists for an error group (e.g 400A, 400B)
            errorText = i18n.t(`rudiError.badRequestError.${errorCode.replace(/[0-9]*$/, '')}`)
        } else if (Object.keys(i18n.t('rudiError.badRequestError')).includes(errorCode)) {
            // if a message exists for a specific error (eg. 400A1, 400C4, 500)
            errorText = i18n.t(`rudiError.badRequestError.${errorCode}`)
        }

        // appends correct support info message
        const supportNecessary = ['500', '400B']
        if (supportNecessary.includes(errorCode) || supportNecessary.includes(errorCode.replace(/[0-9]*$/, ''))) {
            errorText += i18n.t('common.notifications.supportNecessary')
        } else {
            errorText += i18n.t('common.notifications.supportOptional')
        }

        throw new APIError(JSON.stringify(errorApiObject, null, 2), null, null, errorText)
    }

    /**
     * A Function to Fetch a JSON from a URL with keep alive connecton
     *
     * @param requestURL            URL to fetch
     * @param requestOptions        Options for the request
     * @returns {Promise<void>}     JSON from the URL
     * @private
     */
    // eslint-disable-next-line class-methods-use-this
    async _fetchJSONFromURLKeepAlive (requestURL, requestOptions) {
        let buffer_for_chunks = ''
        requestOptions.headers['Content-Type'] = 'text/event-stream'
        return fetch(
            requestURL,
            requestOptions
        )
            .then(response => response.body.getReader())
            .then(reader => {
                return reader.read().then(function processResult (result) {
                    const decoder = new TextDecoder('utf-8');
                    const chunk = decoder.decode(result.value , { stream: true });

                    if (chunk !== ' ') {
                        buffer_for_chunks += chunk
                    }

                    if (result.done) {
                        return JSON.parse(buffer_for_chunks)
                    }

                    return reader.read().then(processResult)
                })
            })
    }
}

export default RUDIAPIConnector
