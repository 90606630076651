class NumberUnit {
    value;
    unit;

    constructor (value, unit) {
        this.value = value;
        this.unit = unit;
    }

    /**
     * Serializes an entity to make it fitting for API requests, especially RUDI API requests.
     */
    // eslint-disable-next-line class-methods-use-this
    serialize () {
        return {
            value: this.value,
            unit: this.unit,
        };
    }

    static deserialize (object) {
        if (!object) {
            return new NumberUnit(undefined, undefined);
        }
        return new NumberUnit(object.value, object.unit);
    }


    /**
     * Formats the Unit, if the unit exists. Format it like (unit) otherwise return an empty string.
     *
     * @returns {string} The formatted unit.
     */
    formatUnit () {
        return this.unit ? `(${this.unit})` : '';
    }
}

export default NumberUnit;