import {GraphColoring} from '@/scripts/Algorithms/DependencyValidator/Color';

class Node {
    /**
     * The constructor for the Node class.
     *
     * @param {string} name                 The name of the node. Should be unique.
     * @param {String[]} inputs             An Array of possible Inputs for the node.
     * @param {String[]} outputs            An Array of possible Outputs for the node.
     * @param {Boolean} isSource            A boolean indicating whether the node is a source node.
     * @param {Boolean} isSink              A boolean indicating whether the node is a sink node.
     * @param {String} inputRelationKind    The relation kind of the node. Cculd Either Be "AND" or "OR". This regards to
     *                                      relates to the need of inputs.
     * @param {String} outputRelationKind   The relation kind of the node. Cculd Either Be "AND" or "OR". This regards to
     *                                      relates to the need of outputs.
     */
    constructor (name, inputs, outputs, isSource, isSink, inputRelationKind = 'AND', outputRelationKind = 'AND') {
        this.name = name;
        this.inputs = inputs;
        this.outputs = outputs;
        this.isSource = isSource;
        this.isSink = isSink;
        this.inputRelationKind = inputRelationKind;
        this.outputRelationKind = outputRelationKind;
        this.color = GraphColoring.WHITE;
        this.visited = 0;
        this.failureReason = [];
        this._adjacentRelations = [];
        this._usedInputs = [];
    }

    /**
     * Returns all the adjacent nodes of the node.
     *
     * @returns {Node[]}    An array of adjacent nodes.
     */
    get adjacentNodes () {
        return this.adjacentRelations
            .map(relation => relation.targetNode);
    }

    /**
     * Returns all the adjacent relations of the node.
     *
     * @returns {AdjacencyRelation[]}    An array of adjacent relations.
     */
    get adjacentRelations () {
        return this._adjacentRelations;
    }


    /**
     * reset the usage of the Node by setting the value to WHITE
     */
    reset () {
        this.color = GraphColoring.WHITE
        this.visited = 0
        this.failureReason = []
        this._usedInputs = []
    }

    /**
     *
     * @param {String} input    The input to use
     */
    useInput (input) {
        if (input === null) {
            return
        }

        // check if the input is already used
        if (this._usedInputs.includes(input)) {
            return
        }
        // if the input is not in inputs, return
        if (!this.inputs.includes(input)) {
            return
        }

        // add the input to the used inputs
        this._usedInputs.push(input)
    }

    /**
     * Use Input for Node, and return the maximum possible coloring for the node
     * 1. If all needed Inputs are set, set the color to black
     * 2. If the Node is reached but not all needed inputs are used, set the color to gray
     *
     * Needed Inputs define them self through the "inputs" property and the relation. If the Relation is "OR",
     * it is sufficient to have one import set, and all the color will be black. If the relation is "AND"
     * all the imports have to be set.
     *
     * @return {String}     The maximum possible color for the node
     */
    getPossibleColor () {
        // check if all needed inputs are set
        if (this.inputRelationKind === 'AND' && this.inputs.length === this._usedInputs.length) {
            return GraphColoring.BLACK
        } else if (this.inputRelationKind === 'OR' && this._usedInputs.length > 0) {
            return GraphColoring.BLACK
        } else if (this._usedInputs.length > 0) {
            return GraphColoring.GRAY
        } else {
            return GraphColoring.WHITE
        }
    }

    /**
     * Adds a relation to the current Node, if one of the outputs of the current node is equal to the input of the
     *
     *
     * @param {Node} targetNode     The target node of the relation.
     */
    connectTargetNodeOnMatching (targetNode) {
        const matchingOutput = this.outputs.find(output => targetNode.inputs.includes(output));
        if (matchingOutput) {
            this.adjacentRelations.push(new AdjacencyRelation(targetNode, matchingOutput));
        }
    }
}


class AdjacencyRelation {
    /**
     * The constructor for the Relation class.
     *
     * @param {Node} targetNode     The source node of the relation.
     * @param {String} connection   The connection between the source and the target node. Could be the Energy transfered
     *                              between the nodes.
     */
    constructor (targetNode, connection) {
        this.targetNode = targetNode;
        this.connection = connection;
    }
}

export default Node;