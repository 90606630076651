// import exampleObject from '../assets/examples/objects/automotive_berlin'
import defaultScenarios from '../assets/examples/scenarios/default_scenarios'

export default {
    language: process.env.VUE_APP_I18N_LOCALE,
    currentPageIndex: 0,
    objectDescription: {}, // exampleObject.objectDescription,
    buildingGroups: [], // exampleObject.buildingGroups,
    climateData: {},
    buildingTypes: [],
    energyTypes: [],
    // an array of aggregated energies from all buildings. One entry being an energy object with a values array that holds the sum of the values of this energy type of all buildings
    objectEnergies: [],
    scenarios: defaultScenarios,
    scenarioOptions: {},
    globalCustomAttributes: [],
    // a list of available units in the application for the user to choose from
    availableUnits: ['%', '1/°C', 'Nm³/h', 'W/m²', 'kW', 'kW/m²', 'm/s', 'm²', 'm³/h', '°C', '€', '€/kWh'].sort(),
    scenarioCalculationOptions: {
        withPreview: true,
        withPlainResults: false
    }
}
